import {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useCompany } from '@shared-admin-kit/core';
import { useTranslation } from '@shared-admin-kit/translation';
import { useSnackbar } from '@scaleflex/ui/snackbar';


function useUsersShare() {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const {
    refreshCurrentCompany, activeCompany, isCompanyLoading, resendInvitation, cancelInvitation,
    deleteSharedUser, shareUser, editSharedUser,
    resendingInvitationUuid: resendingUuid,
    cancelingInvitationUuid: cancelingUuid,
    deleteingSharedUserUuid: deletingUuid,
  } = useCompany();
  const users = activeCompany?.users;
  const invitedUsers = activeCompany?.invited_users;
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isUsersInviting, setIsUsersInviting] = useState(false);
  const [selectedUsersSet, setSelectedUsersSet] = useState(new Set());
  const isLoading = isCompanyLoading || isUsersLoading;
  const projectsList = useMemo(() => (activeCompany?.projects || [])
    .map((project) => ({ name: project?.name, uuid: project?.uuid })), [activeCompany?.projects]);

  // useEffect(() => {
  //   const uuidsHash = {};
  //   (activeCompany?.projects || []).forEach(p => uuidsHash[p.uuid] = p.name || null);
  //   setProjectsUuidsHash(uuidsHash);
  // }, [activeCompany?.projects]);
  const refreshUsers = useCallback(() => {
    setIsUsersLoading(true);
    refreshCurrentCompany()
      .catch(() => {
        showMessage(
          t('UNABLE_TO_FETCH_COMPANY_USERS', 'Unable to fetch company users! Contact us!'),
          { variant: 'error' },
        );
      })
      .finally(() => setIsUsersLoading(false));
  }, []);

  useEffect(() => {
    refreshUsers();
  }, []);

  const shareWithUser = (share) => {
    setIsSharing(true);
    return (share?.uuid ? editSharedUser(share?.uuid, share) : shareUser(share))
      .then(() => {
        showMessage(`${t('SHARED_FOR_USER', 'Shared for user')} ${share?.email_address}`);
        refreshUsers();
      })
      .catch((error) => {
        showMessage(`${t('USER_SHARE_ERROR', 'User share error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      })
      .finally(() => setIsSharing(false));
  };

  const inviteUsers = (invitations) => {
    const requests = (invitations || []).map((share) => shareUser(share));
    setIsUsersInviting(true);

    return Promise.all(requests)
      .then(() => {
        showMessage(t('USERS_INVITED', 'Users invited'));
        refreshUsers();
      })
      .catch((error) => {
        showMessage(`${t('USERS_INVITATION_ERROR', 'User invitations error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      })
      .finally(() => setIsUsersInviting(false));
  };

  const handleDeleteUserShare = (shareUuid) => {
    deleteSharedUser(shareUuid)
      .then(() => {
        showMessage(t('SHARE_USER_DELETED', 'Shared user deleted'));
        refreshUsers();
      })
      .catch((error) => {
        showMessage(`${t('SHARE_USER_DELETE_ERROR', 'Shared user delete error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      });
  };

  const handleResendInvitation = (inviteUuid) => {
    resendInvitation(inviteUuid)
      .then(() => {
        showMessage(t('INVITATION_RESENT', 'Invitation resent!'));
      })
      .catch((error) => {
        showMessage(`${t('INVITATION_RESEND_ERROR', 'Invitation resend error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      });
  };

  const handleCancelInvitation = (inviteUuid) => {
    cancelInvitation(inviteUuid)
      .then(() => {
        showMessage(t('INVITATION_CANCELED', 'Invitation canceled'));
        refreshUsers();
      })
      .catch((error) => {
        showMessage(`${t('INVITATION_CANCEL_ERROR', 'Invitation cancel error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      });
  };
  const handleRemoveUsers = () => {
    //TODO: Swicth to single request when BE endpoint will be created
    const invitedUsersUuids = invitedUsers.map((user) => user.invite_uuid);
    const requests = ([...selectedUsersSet] || []).map((userUuid) => (
      invitedUsersUuids.includes(userUuid)
        ? cancelInvitation(userUuid)
        : handleDeleteUserShare(userUuid)
    ));

    return Promise.all(requests)
      .then(() => {
        refreshUsers();
        setSelectedUsersSet(new Set());
      })
      .catch((error) => {
        showMessage(`${t('REMOVING_USERS_ERROR', 'Removing users error!')} ${
          error?.response?.data?.msg || error.message || ''}`, { variant: 'error' });
      })
      .finally(() => showMessage(t('USERS_REMOVED', 'Users Removed Successfully!')));
  };

  return {
    setSelectedUsersSet,
    selectedUsersSet,
    isLoading,
    isSharing,
    isUsersInviting,
    deletingUuid,
    resendingUuid,
    cancelingUuid,
    users,
    invitedUsers,
    refreshUsers,
    shareWithUser,
    inviteUsers,
    removeUsers: handleRemoveUsers,
    deleteUserShare: handleDeleteUserShare,
    resendInvitation: handleResendInvitation,
    cancelInvitation: handleCancelInvitation,
    projectsList,
  };
}

export {
  useUsersShare,
};
