import styled from 'styled-components';
import { PC } from '../typography';


const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopNavWrap = styled.div(
  ({ theme: { palette } }) => `
    border-bottom: 1px solid ${palette[PC.BordersPrimary]};
  `,
);

const Styled = {
  Content,
  TopNavWrap,
};

export default Styled;
