import styled, { css } from 'styled-components';
import { ModalContent as SfxModalContent } from '@scaleflex/ui/core';


const StyledModalContent = styled(SfxModalContent)(
  () => css`
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    flex-grow: 1;
    text-align: left;
  `,
);

export default StyledModalContent;
