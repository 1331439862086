const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const ROLE = {
  // READONLY: 'readonly',
  // STATS_ACCESS: 'stats_access',
  // BILLING_ACCESS: 'billing_access',
  // DEVELOPER_ACCESS: 'developer_access',
  // LIGHT_ACCESS: 'light_access',
  REVIEWER: 'reviewer',
  TRANSLATOR: 'translator',
  SUPER_EDITOR: 'super-editor',
  ADMIN: 'admin',
  OWNER: 'owner',
};

const SCOPE = {
  COMPANY: 'company',
  PROJECT: 'project',
};

const ROLE_OPTIONS = [
  // { value: ROLE.READONLY, label: 'Readonly' },
  // { value: ROLE.STATS_ACCESS, label: 'Stats access' },
  // { value: ROLE.BILLING_ACCESS, label: 'Billing access' },
  // { value: ROLE.DEVELOPER_ACCESS, label: 'Developer access' },
  // { value: ROLE.LIGHT_ACCESS, label: 'Light access' },
  // { value: ROLE.OWNER, label: 'Owner' },
  { value: ROLE.REVIEWER, label: 'Reviewer: Read only' },
  { value: ROLE.TRANSLATOR, label: 'Translator: Edit only one language' },
  { value: ROLE.SUPER_EDITOR, label: 'Super-Editor: Edit all language' },
  { value: ROLE.ADMIN, label: 'Admin: Read, Edit, Add User' },
];

const SCOPE_OPTIONS = [
  { value: SCOPE.COMPANY, label: 'Company scope' },
  { value: SCOPE.PROJECT, label: 'Project scope' },
];

export {
  EMAIL_REGEX,
  ROLE,
  SCOPE,
  ROLE_OPTIONS,
  SCOPE_OPTIONS,
};
