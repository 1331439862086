import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCompany } from '@shared-admin-kit/core';
import Routes from './routes';
import { fetchAvailableLanguagesAction } from './actions/available-languages.action';
import { updateMainClientInstanceHeaders } from './services/api/client';
import { widgetModalContainerId } from './components/image-field/image-field';
import './widget.styles';


function App() {
  const { activeProjectUuid } = useCompany();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAvailableLanguagesAction());
  }, []);

  useEffect(() => {
    updateMainClientInstanceHeaders();
  }, [activeProjectUuid]);

  return (
    <>
      <Routes />
      <div id={widgetModalContainerId} style={{ zIndex: 10000, position: 'relative' }} />
    </>
  );
}

export default App;
