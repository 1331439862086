import PT from 'prop-types';


function TextWithHighlights({ text, hightlightText, hightlightBackgroundColor }) {
  if (!text || !hightlightText) { return text; }

  const regexp = new RegExp(hightlightText, 'gi');
  const includesSubStr = regexp.test(text || '');

  if (!includesSubStr) { return text; }

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replace(regexp, `<span style="background: ${hightlightBackgroundColor};">$&</span>`),
      }}
    />
  );
}

TextWithHighlights.defaultProps = {
  text: '',
  hightlightText: '',
  hightlightBackgroundColor: 'rgb(104 121 235 / 17%)',
};

TextWithHighlights.propTypes = {
  text: PT.string,
  hightlightText: PT.string,
  hightlightBackgroundColor: PT.string,
};

export default TextWithHighlights;
