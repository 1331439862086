// import getDateString from '@filerobot/utils/lib/getDateString';
// eslint-disable-next-line import/no-named-default
// import getTime from '@filerobot/utils/lib/getTime';
// import getTimeStamp from '@filerobot/utils/lib/getTimeStamp';

// Copied from Widget (@filerobot/utils/lib/getDateString)
function getDateString(format, spaceType, timeStamp) {
  const dateObject = timeStamp ? new Date(timeStamp) : new Date();

  const getMonth = dateObject.getMonth() + 1;
  const getDay = dateObject.getDate();

  const month = getMonth < 10 ? `0${getMonth}` : getMonth;
  const day = getDay < 10 ? `0${getDay}` : getDay;
  const year = dateObject.getFullYear();

  switch (format) {
    case 'mdy':
      return month + spaceType + day + spaceType + year;
    case 'dmy':
      return day + spaceType + month + spaceType + year;
    case 'ymd':
      return year + spaceType + month + spaceType + day;
    default: return '';
  }
}

const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000 * (-1);

const getTime = (timestamp, showAmPm = false) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}${showAmPm ? ` ${ampm}` : ''}`;
};

const generateDatetime = (datetimeStr) => ( // ".toISOString().slice(0, 16)" taken from Widget
  new Date(new Date(datetimeStr) - TIMEZONE_OFFSET).toISOString().slice(0, 16)
);

const getFormattedDateTime = (datetimeStr, opt) => {
  const options = {
    format: 'ymd', // 'YYYY.MM.DD H:mm',
    spaceType: '.',
    withTimezoneOffset: true,
    ...(opt || {}),
  };
  let timestamp = Date.parse(datetimeStr);

  if (options.withTimezoneOffset) {
    timestamp += TIMEZONE_OFFSET;
  }

  return `${getDateString(options.format, options.spaceType, timestamp)} ${getTime(timestamp)}`;
  // return _getFormattedDateTime(timestamp, options.format);
};

export {
  TIMEZONE_OFFSET,
  getTime,
  getDateString,
  generateDatetime,
  getFormattedDateTime,
};
