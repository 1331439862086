import {
  SET_LANGUAGE_LIST,
} from '../reducers/available-languages.reducer';
import { fetchAvailableLanguagesRequest } from '../services/api/available-languages.service';


export const fetchAvailableLanguagesAction = () => (dispatch) => (
  fetchAvailableLanguagesRequest().then((response) => {
    dispatch({
      type: SET_LANGUAGE_LIST,
      languages: response?.data?.languages || [],
    });
  // eslint-disable-next-line no-console
  }).catch((error) => { console.log(error); })
);
