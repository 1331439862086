import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const ProjectTitle = styled.div(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['text-normal']}
    line-height: 16px;
    display: flex;
    align-items: center;
    margin: 22px 0px;
    color: ${palette[PC.TextPrimary]};
  `,
);

const Dropdown = styled.div(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['text-normal']}
    color: ${palette[PC.TextSecondary]};
  `,
);

const Styled = {
  ProjectTitle,
  Dropdown,
};

export default Styled;
