import styled, { css } from 'styled-components';
import { PC, FV } from '../typography';
import Box from '../box';


const Container = styled(({ animationDuration, ...rest }) => <div {...rest} />)((
  ({ theme: { palette, typography: { font } }, animationDuration }) => css`
    ${font[FV.TextSmall]}
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: ${palette[PC.AccentPrimaryActive]};
    padding: 8px 60px;
    cursor: pointer;
    opacity: 1;

    :hover {
      background-color: ${palette[PC.AccentPrimaryHover]};
    }

    &.is-adding {
      animation: fadeIn ${animationDuration}ms linear;
    }

    &.is-removing {
      animation: fadeOut ${animationDuration}ms linear;
    }

    @keyframes fadeIn {
      from { opacity: 0;  }
      to { opacity: 1;  }
    }

    @keyframes fadeOut {
      from { opacity: 1;  }
      to { opacity: 0;  }
    }
  `
));

const Notification = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0 12px;
`;

const Icon = styled(Box)`
  display: flex;
  flex-shrink: 0;
`;

const Styled = {
  Container,
  Notification,
  Icon,
};

export default Styled;
