import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import Box from '../box';


const Divider = styled(Box)(
  ({ theme: { palette }, direction = 'horizontal' }) => css`
    width: ${direction === 'horizontal' ? '100%' : '1px'};
    height: ${direction === 'horizontal' ? '1px' : 'auto'};
    background: ${palette[PC.BordersSecondary]};
    box-sizing: border-box;
  `,
);

const Styled = {
  Divider,
};

export default Styled;
