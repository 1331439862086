import axios from 'axios';


function createClient(config = {}) {
  return axios.create({
    ...(config || {}),
  });
}

const defaultHeaders = {
  'X-Version': '2018_R2',
  get 'X-Session-Token'() { return localStorage.getItem('session_uuid'); },
  get 'X-Company-Token'() { return sessionStorage.getItem('company_uuid') || localStorage.getItem('company_uuid'); },
  get 'X-Project-Token'() { return sessionStorage.getItem('project_uuid') || localStorage.getItem('project_uuid'); },
};

const client = createClient({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { ...defaultHeaders },
});

function updateMainClientInstanceHeaders() {
  client.defaults.headers = { ...defaultHeaders };
}

function getCancelToken() {
  return axios.CancelToken;
}

// eslint-disable-next-line import/no-unused-modules
export {
  defaultHeaders,
  client,
  getCancelToken,
  updateMainClientInstanceHeaders,
};
