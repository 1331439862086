import { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledModalContext } from './styled-modal.constants';
import Styled from './styled-modal.styled';

/**
 * @param {ModalProps} props
 * @param {String} props.className
 * @returns {SfxModal}
 * @constructor
 */
function StyledModal(props) {
  const { className } = props;
  const [isModalLocked, setIsModalLocked] = useState(false);

  /** @type {StyledModalContextValue} */
  const _styledModalContextValue = {
    isModalLocked,
    setIsModalLocked,
  };

  /** @type {ModalProps} */
  const _modalProps = {
    ...props,
    className: [className || null, isModalLocked ? 'locked' : null].filter((i) => i).join(' '),
    onClose: isModalLocked
      ? () => null
      // eslint-disable-next-line react/prop-types,react/destructuring-assignment
      : props.onClose,
  };

  return (
    <StyledModalContext.Provider value={_styledModalContextValue}>
      <Styled.StyledModal {..._modalProps} />
    </StyledModalContext.Provider>
  );
}

StyledModal.propTypes = {
  className: PropTypes.string,
};

export default StyledModal;
