import { Suspense } from 'react';
import PT from 'prop-types';
import { useCompany } from '@shared-admin-kit/core';
import { useGlobalNotifications } from '../../providers/global-notifications-provider';
import MainLoader from '../main-loader';
import GlobalNotification from '../global-notification';
import TopNav from './components/top-nav';
import Styled from './layout.styles';


// function WrappedRoute(props) {
//   return (
//     <RouteComponent
//       {...props}
//       wrap={(content) => (
//         <Suspense fallback={<MainLoader />}>
//           {content}
//         </Suspense>
//       )}
//       loader={<MainLoader />}
//     />
//   );
// }

function Layout({ children }) {
  const { isProjectLoading } = useCompany();
  const { notifications } = useGlobalNotifications();

  return (
    <>
      <Styled.TopNavWrap>
        <TopNav />
        {(notifications || []).length > 0 && notifications.map(({ uuid, notification }) => (
          <GlobalNotification key={uuid} uuid={uuid} notification={notification} />
        ))}
      </Styled.TopNavWrap>

      <Styled.Content>
        <Suspense fallback={<MainLoader />}>
          {isProjectLoading ? <MainLoader /> : children}
        </Suspense>
      </Styled.Content>
    </>
  );
}

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PT.node,
};

export default Layout;
