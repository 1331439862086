import styled from 'styled-components';


const TabPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px 20px;
  overflow: auto;
`;

const TabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default TabPanelWrapper;

// eslint-disable-next-line import/no-unused-modules
export {
  TabPanelContainer,
};
