import { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { useTheme } from '@scaleflex/ui/theme/hooks';
import UserIcon from '@scaleflex/icons/user';
import { getColorByString } from '../../utils';
import Spinner from '../spinner';
import Box from '../box';
import { PC } from '../typography';
import Styled from './avatar.styles';


function Avatar({
  src, alt, children, size = 36, fontSize, loading,
}) {
  const theme = useTheme();
  const [showImage, setShowImage] = useState(Boolean(src));
  const [isImageLoading, setIsImageLoading] = useState(showImage);
  const isLoading = loading || isImageLoading;
  const [firstLetter = null, bgColor = null] = useMemo(() => (
    alt?.[0] ? [alt?.[0], getColorByString(alt)] : [null, theme.palette[PC.AccentPrimary]]
  ), [alt]);

  useEffect(() => {
    setShowImage(Boolean(src));
    setIsImageLoading(Boolean(src));
  }, [src]);

  const renderContent = () => {
    if (loading) {
      return <Spinner size={size / 2} />;
    }
    if (showImage) {
      return (
        <>
          <img
            src={src}
            onLoad={() => setIsImageLoading(false)}
            onError={() => {
              setShowImage(false);
              setIsImageLoading(false);
            }}
            alt={alt}
          />
          {isImageLoading && <Box position="absolute"><Spinner size={size / 2} /></Box>}
        </>
      );
    }
    if (firstLetter) {
      return firstLetter;
    }
    if (children) {
      return children;
    }

    return <UserIcon size={size / 2.25} />;
  };

  return (
    <Styled.Avatar bgColor={!isLoading && !showImage ? bgColor : null} size={size} fontSize={fontSize}>
      {renderContent()}
    </Styled.Avatar>
  );
}

Avatar.defaultProps = {
  size: 36,
  src: '',
  alt: '',
  children: null,
  loading: false,
};

Avatar.propTypes = {
  src: PT.string,
  alt: PT.string,
  children: PT.node,
  size: PT.number,
  fontSize: PT.oneOfType([PT.number, PT.string]),
  loading: PT.bool,
};

export default Avatar;
