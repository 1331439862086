import styled, { css } from 'styled-components';
import { FontVariant as FV } from '@scaleflex/ui/utils/types/typography/font-variant';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const commonItemMixin = ({ active, theme: { palette, typography: { font } } }) => css`
  ${font[FV.LabelEmphasis]}
  color: ${palette[active ? PC.AccentPrimary : PC.TextPrimary]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${!active && css`cursor: pointer;`}
  .ProjectIcons {
    color: ${palette[active ? PC.AccentPrimary : PC.TextPrimary]};
  }
  svg {
    color: ${palette[PC.IconsPrimary]};
  }
`;
const CompanyItem = styled.div(
  ({ theme: { palette } }) => css`
  font-weight: 400;
  color: ${palette[PC.TextPrimary]};
`,
);

const ProjectItem = styled(({ active, ...rest }) => <div {...rest} />)((
  ({ active, theme }) => css`
    ${commonItemMixin({ active, theme })}
    display: flex;
    font-size: 12px;
    line-height: 14px;
    height: 14px;
    padding: 7px 54px 7px 50px;
    ${active && css`
      box-shadow: inset 2px 0px 0px ${theme.palette[PC.AccentPrimary]};
    `}
   .edit-project {
    display: none;
       }
     &:hover {
      .edit-project {
       display: inline;
    }
  }
  `
));

const EmptyProjectItem = styled.div(
  ({ theme: { palette } }) => css`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 7px 54px 7px 50px;
  position: relative;
  color: ${palette[PC.TextPrimary]};
`,
);

const Styled = {
  CompanyItem,
  ProjectItem,
  EmptyProjectItem,
};

export default Styled;
