import PT from 'prop-types';
import Styled from './divider.styles';


function Divider({ direction, ...rest }) {
  return (
    <Styled.Divider direction={direction} {...rest} />
  );
}

Divider.defaultProps = {
  direction: 'horizontal',
};

Divider.propTypes = {
  direction: PT.string,
};

export default Divider;
