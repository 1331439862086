import styled from 'styled-components';
import { objectValues } from '@scaleflex/ui/utils/functions';
import { PropName } from './types';
import { propNameMixin } from './box.mixin';
import { filterObjectByPropName } from './box.utils';


const Box = styled(
  ({ component: Component = 'div', style, ...rest }) => (
    <Component style={style} {...filterObjectByPropName(rest, objectValues(PropName))} />
  ),
)(
  (props) => (
    Object.keys(props)
      .filter((propName) => Boolean(propNameMixin[propName]))
      .map((propName) => propNameMixin[propName](props[propName]))
  ),
);

const Styled = {
  Box,
};

export default Styled;
