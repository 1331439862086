import { useEffect, useState, useMemo } from 'react';
import { useAuth } from '@shared-admin-kit/core';
import { setUserMetaRequest } from '@shared-admin-kit/core/lib/modules/auth/auth.service';
import { useTranslation } from '@shared-admin-kit/translation';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import { getCloudimageV7Url } from '../utils';
// import md5 from 'md5';

function useCurrentUser() {
  const { t } = useTranslation();
  const { user, isUserLoading, updateSession } = useAuth();
  const { showMessage } = useSnackbar();
  const email = user?.email || user?.user_email;
  const [isInited, setIsInited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isLoading = isUserLoading || !isInited || isSaving; // || isSessionDataLoader;
  const avatar = useMemo(() => (
    getCloudimageV7Url(user?.infos?.photo_uri, { h: 100, w: 100 })
  ), [user?.infos?.photo_uri]);
  // `https://www.gravatar.com/avatar/${md5(email)}?d=mp`;
  const name = useMemo(() => {
    if (user?.infos?.nickname) return user?.infos?.nickname;

    const firstName = user?.infos?.first_name;
    const lastName = user?.infos?.last_name;

    if (firstName || lastName) return [firstName, lastName].filter((i) => i).join(' ');

    return '';
  }, [user]);

  useEffect(() => {
    if (email) setIsInited(true);
  }, [email]);

  const changeUserProfilePhoto = (newPhoto = null) => {
    setIsSaving(true);
    setUserMetaRequest('photo_uri', newPhoto)
      .then(() => {
        updateSession(false, false);
      })
      .catch((error) => showMessage(
        error?.response?.data?.msg || t('PROFILE_PHOTO_SAVING_ERROR', 'Profile photo saving error!'),
        { variant: 'error' },
      ))
      .finally(() => { setIsSaving(false); });
  };

  return {
    user, name, email, avatar, isLoading, changeUserProfilePhoto,
  };
}

export {
  useCurrentUser,
};
