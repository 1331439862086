import { useState } from 'react';
import { useCurrentUser } from '../../../../../../hooks';
import PasswordSettingsModal from '../../../../../password-settiings-modal';
import Dropdown from '../../../../../dropdown';
import UserSettingsModal from '../../../../../user-settings-modal';
import Avatar from '../../../../../avatar';
import DropdownContent from './components/dropdown-content';
import Styled from './personal-settings.styles';


function PersonalSettings() {
  const {
    avatar, name: userName, email, isLoading: isUserLoading, changeUserProfilePhoto, user,
  } = useCurrentUser();
  const [isOpenUserSettingsModal, setOpenUserSettingsModal] = useState(false);
  const [isOpenPasswordSettingsModal, setOpenPasswordSettingsModal] = useState(false);

  return (
    <>
      <Dropdown
        label={(
          <Styled.Dropdown>
            {!isUserLoading && (<Avatar src={avatar} alt={userName} />)}
          </Styled.Dropdown>
        )}
        fullWidth={false}
        style={{ width: 218, overflowY: 'auto' }}
        showArrow={false}
        dropdownStyle={{ marginLeft: 16 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-60, 0],
              },
            },
          ],
        }}
      >
        {({ onClose }) => (
          <DropdownContent
            onClose={onClose}
            setOpenUserSettingsModal={setOpenUserSettingsModal}
            setOpenPasswordSettingsModal={setOpenPasswordSettingsModal}
            avatar={avatar}
            userName={userName}
            email={email}
            isUserLoading={isUserLoading}
            changeUserProfilePhoto={changeUserProfilePhoto}
            user={user}
          />
        )}
      </Dropdown>

      <UserSettingsModal
        open={isOpenUserSettingsModal}
        onClose={() => setOpenUserSettingsModal(false)}
      />

      <PasswordSettingsModal
        open={isOpenPasswordSettingsModal}
        onClose={() => setOpenPasswordSettingsModal(false)}
      />
    </>
  );
}

export default PersonalSettings;
