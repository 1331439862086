import { createContext, useState, useEffect } from 'react';
import PT from 'prop-types';
import { useTranslation } from '@shared-admin-kit/translation';
import { useGlobalNotificationsStorage } from './hooks/use-global-notifications-storage';


const GlobalNotificationsContext = createContext();
const SETTINGS_CHANGED_TRANSLATION_KEY = 'SETTINGS_CHANGED.FEW MINUTES TO PROPAGATE';
// eslint-disable-next-line max-len
const SETTINGS_CHANGED_DEFAULT_TRANSLATION = 'You made changes in settings. It takes few minutes to propagate and become effective.';

function GlobalNotificationsProvider({ children, animationDuration = 500, defaultNotificationLifeSpanSec = 180 }) {
  const { t } = useTranslation();
  const storage = useGlobalNotificationsStorage({ defaultNotificationLifeSpanSec });
  const [notifications, setNotifications] = useState([]);
  const addNotification = (notification, lifeSpanSec) => setNotifications(storage.add(notification, lifeSpanSec));
  const addSettingsChangedNotification = () => {
    addNotification(t(SETTINGS_CHANGED_TRANSLATION_KEY, SETTINGS_CHANGED_DEFAULT_TRANSLATION));
  };
  const removeNotification = (uuid) => {
    const newNotifications = storage.remove(uuid);
    setTimeout(() => setNotifications(newNotifications), animationDuration); // Wait hide animation
  };
  const cleanNotifications = () => {
    storage.clean();
    setNotifications([]);
  };

  useEffect(() => {
    setNotifications(storage.getList());
  }, []);

  return (
    <GlobalNotificationsContext.Provider
      value={{
        animationDuration,
        notifications,
        addNotification,
        addSettingsChangedNotification,
        removeNotification,
        cleanNotifications,
      }}
    >
      {children}
    </GlobalNotificationsContext.Provider>
  );
}

GlobalNotificationsProvider.propTypes = {
  children: PT.node,
  animationDuration: PT.number,
  defaultNotificationLifeSpanSec: PT.number,
};


export {
  GlobalNotificationsContext,
  GlobalNotificationsProvider,
  SETTINGS_CHANGED_TRANSLATION_KEY,
  SETTINGS_CHANGED_DEFAULT_TRANSLATION,
};
