import PT from 'prop-types';
import { Folder } from '@scaleflex/icons';
import { useCompany } from '@shared-admin-kit/core';
import { T } from '@shared-admin-kit/translation';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Accordion from '@scaleflex/ui/core/accordion';
import { useState } from 'react';
import { Tooltip, Button } from '@scaleflex/ui/core';
import RenameIcon from '@scaleflex/icons/rename';
import Box from '../../../box';
import TextWithHighlights from '../../../text-with-highlights';
import Styled from './company.styles';
import { AUTHENTICATED_ROUTES } from '../../../../routes';


function Company({
  company, active, searchTerm, activeProjectUuid, activateProject, onCloseTenancySelector, onEditProject,
}) {
  const [accordionExpanded, setAccordionExpanded] = useState(active);
  const dispatch = useDispatch();
  const { selectCompany } = useCompany();
  const handleCompanyClick = () => {
    if (!active) {
      selectCompany(company.uuid);
      onCloseTenancySelector();
    }
    dispatch(push(AUTHENTICATED_ROUTES.GRIDS));
  };
  const handleProjectClick = (projectUuid) => () => {
    activateProject(projectUuid, company.uuid);
    dispatch(push(AUTHENTICATED_ROUTES.GRIDS));
  };

  return (
    <div>
      <Accordion
        key={company.uuid}
        expanded={accordionExpanded || Boolean(searchTerm)}
        detailStyle={{ margin: '0px' }}
        headerStyle={{ padding: '12px 20px 6px 12px', height: 14, lineHeight: 0 }}
        label={(
          <Styled.CompanyItem active={active} onClick={handleCompanyClick}>
            <TextWithHighlights text={company.name} hightlightText={searchTerm} />
          </Styled.CompanyItem>
          )}
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        {company?.projects?.itemsArray.length > 0
          ? company?.projects?.itemsArray.map(({ uuid, name }) => (
            <Styled.ProjectItem
              key={uuid}
              active={activeProjectUuid && activeProjectUuid === uuid}
              onClick={handleProjectClick(uuid)}
            >
              <Box active={activeProjectUuid && activeProjectUuid === uuid} style={{ color: 'white' }}>
                <Folder className="ProjectIcons" size={12} />
              </Box>
              <Box style={{ marginLeft: 6 }}>
                <TextWithHighlights text={name} hightlightText={searchTerm} />
              </Box>
              {
                activeProjectUuid && activeProjectUuid === uuid
              && (
              <Box component="span" className="edit-project">
                <Tooltip
                  arrow
                  position="bottom"
                  size="sm"
                  title="Edit project"
                >
                  <Button
                    style={{ padding: '0px 0px 0px 4px' }}
                    color="link"
                    size="xs"
                    onClick={() => { onEditProject(); }}
                    icon={<RenameIcon className="ProjectIcons" size={8} />}
                  />
                </Tooltip>
              </Box>
              )
              }
            </Styled.ProjectItem>
          ))
          : (
            <Styled.EmptyProjectItem>
              <T i18nKey="NO_PROJECTS" defaultValue="No projects" />
            </Styled.EmptyProjectItem>
          )}
      </Accordion>
    </div>
  );
}

Company.defaultProps = {
  searchTerm: '',
};

Company.propTypes = {
  company: PT.shape({
    uuid: PT.string.isRequired,
    name: PT.string,
    slug: PT.string,
    projects: PT.shape({
      count: PT.number,
      uuids: PT.arrayOf(PT.string),
      items: PT.objectOf(PT.string), // {[project.uuid]: project.name}
      itemsArray: PT.arrayOf(PT.object),
    }),
  }).isRequired,
  active: PT.bool.isRequired,
  searchTerm: PT.string,
  activeProjectUuid: PT.string,
  activateProject: PT.func.isRequired,
  onCloseTenancySelector: PT.func.isRequired,
  onEditProject: PT.func,
};

export default Company;
