import { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import {
  Power, User, LogOut, Lock, Rename as EditIcon, Location, // World, CloudUpload, Process, Rocket,
} from '@scaleflex/icons';
import { useTheme } from '@scaleflex/ui/theme/hooks';
import { useAuth } from '@shared-admin-kit/core';
import { T /* useTranslation */ } from '@shared-admin-kit/translation';
import { getCloudimageV7Url } from '../../../../../../../../utils';
import Divider from '../../../../../../../divider';
import Box from '../../../../../../../box';
import Typography, { PC, FV } from '../../../../../../../typography';
import Avatar from '../../../../../../../avatar';
import ImageField from '../../../../../../../image-field';
import Styled from './dropdown-content.styles';


const AVATAR_SIZE = 78;

function DropdownContent({
  onClose, setOpenUserSettingsModal, user,
  setOpenPasswordSettingsModal, avatar, isUserLoading, userName, email, changeUserProfilePhoto,
}) {
  const theme = useTheme();
  const { logout } = useAuth();
  // const {
  //   activeLanguage, changeLanguage, t, languages,
  // } = useTranslation();
  const [photoUri, setPhotoUri] = useState(avatar);
  const fullPhotoUri = useMemo(() => getCloudimageV7Url(photoUri, { h: 300, w: 300 }), [photoUri]);

  useEffect(() => { setPhotoUri(avatar); }, [avatar]);

  // const handleLanguageChange = (newLanguageCode) => () => {
  //   onClose();
  //   if (activeLanguage && newLanguageCode === activeLanguage) return;
  //   changeLanguage(newLanguageCode);
  // };

  const firsSection = process.env.REACT_APP_SHOW_LATEST_FEATURE === 'true' ? [
    {
      content: <T i18nKey="GETTING_STARTED" defaultValue="Getting Started" />,
      prefix: <Power size="16" color={theme.palette[PC.LinkPrimary]} />,
      key: 'getting_started',
    },
    {
      content: 'divider',
      disabled: true,
      key: 'getting_started_divider',
    },
  ] : [];

  const renderSecondSection = () => (
    <Styled.MenuItem
      size="md"
      list={[
        ...firsSection,
        {
          content: (
            <Box display="flex" flexDirection="column">
              <Typography mb={0} fontVariant={FV.LabelNormal}>
                <T i18nKey="PERSONAL_SETTINGS" defaultValue="Personal Settings" />
              </Typography>
              <Typography mt="4px" mb={0} fontVariant={FV.LabelSmall} colorVariant={PC.TextSecondary}>
                <T i18nKey="PERSONAL_SETTINGS_HINT" defaultValue="Email, profile, security" />
              </Typography>
            </Box>
          ),
          onClick: () => {
            onClose();
            setOpenUserSettingsModal(true);
          },
          prefix: <User size="16" color={theme.palette[PC.LinkPrimary]} />,
          key: 'user_settings',
        },
      ]}
    />
  );

  const renderThirdSection = () => (
    <Styled.MenuItem
      size="md"
      list={[
        // {
        //   content: `${t('LANGUAGE', 'Language')}${activeLanguage ? ` (${activeLanguage.toUpperCase()})` : ''}`,
        //   prefix: <World size="16" color={theme.palette[PC.LinkPrimary]} />,
        //   key: 'language',
        //   subList: (languages || []).map((item) => ({
        //     content: `${item.label_short} (${item.code.toUpperCase()})`,
        //     key: item.code,
        //     onClick: handleLanguageChange(item.code),
        //     active: activeLanguage && item.code === activeLanguage,
        //   })),
        // },
        {
          content: <T i18nKey="CHANGE_PASSWORD" defaultValue="Change password" />,
          prefix: <Lock size="16" color={theme.palette[PC.LinkPrimary]} />,
          key: 'change_password',
          onClick: () => {
            onClose();
            setOpenPasswordSettingsModal(true);
          },
        },
        {
          content: <T i18nKey="LOG_OUT" defaultValue="Log out" />,
          prefix: <LogOut size="16" color={theme.palette[PC.LinkPrimary]} />,
          key: 'log_out',
          onClick: () => {
            onClose();
            logout();
            window.location = process.env.REACT_APP_AUTH_PUBLIC_URL;
          },
        },
      ]}
    />
  );

  const renderProfileBlock = () => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p="12px"
      background={theme.palette[PC.BackgroundPrimary]}
    >
      <Styled.ImageContainer>
        <ImageField
          value={fullPhotoUri}
          onChange={(file) => {
            const newPhoto = file?.response?.body?.file?.url?.public;

            if (newPhoto) {
              setPhotoUri(newPhoto);
              changeUserProfilePhoto(newPhoto);
            }
          }}
          uploadParams={{ dir: encodeURIComponent('wordplex') }}
          triggerId="avatar-image-widget-trigger"
          renderImage={(value) => (
            <Box className="image-field__image-container">
              {!isUserLoading && (
                <Avatar src={value} alt={userName} size={AVATAR_SIZE} />
              )}
            </Box>
          )}
          renderChangeBtn={({ id }) => (
            <>
              <svg className="clickable-area">
                <circle id={id} cx={AVATAR_SIZE / 2} cy={AVATAR_SIZE / 2} r={AVATAR_SIZE / 2} />
              </svg>
              <EditIcon color="#ffffff" size={16} />
            </>
          )}
          container={process.env.REACT_APP_STORAGE_CONTAINER_NAME}
          securityTemplate={process.env.REACT_APP_STORAGE_UPLOAD_SECURITY_TEMPLATE}
        />
      </Styled.ImageContainer>

      {userName && (
        <Typography mt={1} mb={0} fontVariant={FV.InputMd} colorVariant={PC.TextPrimary} noWrap align="center">
          {userName}
        </Typography>
      )}
      {email && (
        <Typography
          mt={userName ? '4px' : '8px'}
          mb={0}
          fontVariant={FV.InputSm}
          colorVariant={PC.TextSecondary}
          noWrap
          align="center"
        >
          {email}
        </Typography>
      )}
      {user?.infos?.location && (
        <Typography
          mt="4px"
          mb={0}
          fontVariant={FV.InputSm}
          colorVariant={PC.TextSecondary}
          noWrap
          align="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Location size={12} />
          <Box ml="4px">
            {user?.infos?.location}
          </Box>
        </Typography>
      )}
    </Box>
  );

  return (
    <>
      {renderProfileBlock()}
      <Box><Divider /></Box>
      <Box py={1} background="#ffffff">
        {renderSecondSection()}
        <Box my={1}><Divider /></Box>
        {renderThirdSection()}
      </Box>
    </>
  );
}

DropdownContent.defaultProps = {
  avatar: '',
  email: '',
  userName: '',
  isUserLoading: false,
};

DropdownContent.propTypes = {
  onClose: PT.func.isRequired,
  setOpenUserSettingsModal: PT.func.isRequired,
  setOpenPasswordSettingsModal: PT.func.isRequired,
  changeUserProfilePhoto: PT.func.isRequired,
  avatar: PT.string,
  email: PT.string,
  userName: PT.string,
  isUserLoading: PT.bool,
  user: PT.object,
};

export default DropdownContent;
