import styled from 'styled-components';
import { Menu as SfcMenu } from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import { BorderRadiusSize } from '@scaleflex/ui/utils/types/shape/border-radius-size';


const TenancySelector = styled.div((
  ({ theme: { palette, shape: { borderRadius } } }) => `
    display: flex;
    flex-direction: column;
    width: 240px;
    max-height: 350px;
    background: ${palette[PC.BackgroundSecondary]};
    box-shadow: 0px 2px 4px ${palette[PC.LightShadow]};
    border-radius: ${borderRadius[BorderRadiusSize.Md]};
    outline: none;
  `
));

const SearchBlock = styled.div((
  (/* { theme: { palette, typography: { font } } } */) => `
    padding: 12px 12px 8px;
  `
));

const CompaniesBlock = styled.div((
  ({ theme: { palette } }) => `
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${palette[PC.BordersPrimary]};
    overflow-y: auto;
  `
));

const Menu = styled(SfcMenu)`
    overflow: hidden;
`;

const Styled = {
  TenancySelector,
  SearchBlock,
  CompaniesBlock,
  Menu,
};

export default Styled;
