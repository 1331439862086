import { useHistory, useLocation } from 'react-router-dom';
import { T } from '@shared-admin-kit/translation';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { AUTHENTICATED_ROUTES } from '../../../../routes';
import PersonalSettings from './components/personal-settings';
import Styled from './top-nav.styles';
import ProjectsDropdown from '../../../../routes/grids/components/projects-dropdown';


function TopNav() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const renderNavigationItemLink = (route, label) => (
    <Styled.NavigationItem
      className={
        (location.pathname === route || (location.pathname.includes('/g/') && label === 'Grids')) ? 'active' : ''
      }
      onClick={() => dispatch(push(route))}
    >
      <T>{label}</T>
    </Styled.NavigationItem>
  );

  const handleCompanyClick = () => {
    if (location.pathname === AUTHENTICATED_ROUTES.GRIDS) {
      history.go(0);
    } else {
      dispatch(push(AUTHENTICATED_ROUTES.GRIDS));
    }
  };

  return (
    <Styled.TopNav>

      <Styled.LeftBlock>
        <Styled.Logo onClick={handleCompanyClick} />
        <Styled.Navigation>
          {renderNavigationItemLink(AUTHENTICATED_ROUTES.GRIDS, 'Grids')}
          {renderNavigationItemLink(AUTHENTICATED_ROUTES.USERS, 'Users')}
        </Styled.Navigation>
      </Styled.LeftBlock>

      <Styled.RightBlock>
        <ProjectsDropdown />
        <PersonalSettings />
      </Styled.RightBlock>
    </Styled.TopNav>
  );
}

export default TopNav;
