import PT from 'prop-types';
import Arrow from '@scaleflex/ui/core/arrow';
import Styled from '../../../../../../components/dropdown/dropdown.styles';


function ProjectLabel({ children, onClick, open }) {
  return (
    <Styled.Dropdown onClick={onClick}>
      <Styled.DropdownLabel>
        {children}
      </Styled.DropdownLabel>

      <Styled.DropdownIcon
        style={{ padding: '4px 6px 0px 7px', marginLeft: 0 }}
      >
        <Arrow
          IconProps={{ width: 5, height: 12 }}
          type={open ? 'top' : 'bottom'}
        />
      </Styled.DropdownIcon>
    </Styled.Dropdown>
  );
}

ProjectLabel.propTypes = {
  children: PT.node.isRequired,
  onClick: PT.func.isRequired,
  open: PT.bool.isRequired,
};

export default ProjectLabel;
