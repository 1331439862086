/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { PC, FV } from '../typography';


const Avatar = styled(({
  bgColor, size, fontSize, ...rest
}) => <div {...rest} />)((
  ({
    theme: { palette, typography: { font } }, bgColor, size = 36, fontSize,
  }) => css`
    ${font[FV.LabelEmphasis]}
    /* must be 'label-lg-emphasis' */
    /* font-weight: 500; */
    font-size: ${fontSize ? (typeof fontSize === 'number' ? `${fontSize}px` : fontSize) : `${size / 2.25}px`};

    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    line-height: 1;
    user-select: none;
    width: ${size}px;
    height: ${size}px;
    background-color: ${bgColor || palette[PC.BordersPrimary]};
    border-radius: 50%;
    color: ${palette[PC.ButtonPrimaryText]};

    img {
      width: 100%;
      height: 100%;
      color: transparent;
      object-fit: cover;
      text-align: center;
    }
  `
));

const Styled = {
  Avatar,
};

export default Styled;
