import styled from 'styled-components';


const InputGroupContainer = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Styled = {
  InputGroupContainer,
};

export default Styled;
