import styled, { css } from 'styled-components';
import Box from '../box';
import { variantMixin } from './typography.mixin';


const Typography = styled(
  ({
    fontVariant, colorVariant, noWrap,
    ...rest
  }) => <Box component="div" mb={1} {...rest} />,
)(
  ({
    theme: { palette, typography: { font } },
    variant, fontVariant, colorVariant, noWrap,
  }) => css`
    ${variant && variantMixin[variant]}
    ${fontVariant && font[fontVariant]}
    ${colorVariant && palette[colorVariant] ? `color: ${palette[colorVariant]};` : ''}

    ${noWrap && `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    `}
  `,
);

const Styled = {
  Typography,
};

export default Styled;
