import PT from 'prop-types';
import { useEffect } from 'react';
import { useTranslation, T } from '@shared-admin-kit/translation';
import EditIcon from '@scaleflex/icons/rename';
import { Label } from '@scaleflex/ui/core';
import UserSettingModalStyled from '../../../../user-settings-modal.styles';
import { getCloudimageV7Url } from '../../../../../../utils';
import Box from '../../../../../box';
import ImageField from '../../../../../image-field';
import Avatar from '../../../../../avatar';
import FormInputGroup from '../../../../../form-input-group';
import FormTextareaGroup from '../../../../../form-textarea-group';
import Styled from './general-form.styles';


const AVATAR_SIZE = 190;

/**
 * @param {GeneralFormData} form
 * @param errors
 * @param setValue
 * @param register
 * @returns {JSX.Element}
 * @constructor
 */
function GeneralForm({
  form, errors, setValue, register, user, isUserLoading = false, trigger, dirtyFields,
}) {
  const { t } = useTranslation();
  const infos = user?.infos;

  useEffect(() => {
    register('first_name');
    register('last_name');
    register('photo_uri');
    register('nickname');
    register('location');
    register('bio');
  }, [register]);

  const fieldProps = {
    form, errors, setValue, trigger, dirtyFields,
  };

  return (
    <>
      <Box display="flex">
        <Box flex={1} display="flex" flexDirection="column">
          <Label error={Boolean(errors.structure_type)}>
            <T i18nKey="USER_SETTINGS.GENERAL.PROFILE_PICTURE" defaultValue="Profile picture" />
          </Label>

          <Styled.ImageContainer>
            <ImageField
              value={getCloudimageV7Url(form?.photo_uri, { h: 300, w: 300 })}
              onChange={(file) => {
                if (file?.response?.body?.file?.url?.public) {
                  setValue('photo_uri', file?.response?.body?.file?.url?.public, { shouldDirty: true });
                }
              }}
              uploadParams={{ dir: 'avatars' }}
              triggerId="avatar-image-widget-trigger"
              renderImage={(value) => (
                <Box className="image-field__image-container">
                  {!isUserLoading && (
                    <Avatar src={value} alt={infos?.fist_name} size={AVATAR_SIZE} />
                  )}
                </Box>
              )}
              renderChangeBtn={({ id }) => (
                <>
                  {/* Use <circle/> to support circle cursor pointer and hover styles */}
                  <svg className="clickable-area">
                    <circle id={id} cx={AVATAR_SIZE / 2} cy={AVATAR_SIZE / 2} r={AVATAR_SIZE / 2} />
                  </svg>
                  <EditIcon color="#ffffff" size={16} />
                </>
              )}
              container={process.env.REACT_APP_STORAGE_CONTAINER_NAME}
              securityTemplate={process.env.REACT_APP_STORAGE_UPLOAD_SECURITY_TEMPLATE}
            />
          </Styled.ImageContainer>
        </Box>

        <Box flex={1} ml={2}>
          <UserSettingModalStyled.FieldsRow>
            <FormInputGroup
              {...fieldProps}
              label={t('USER_SETTINGS.GENERAL.FIRST_NAME', 'First Name')}
              name="first_name"
            />
          </UserSettingModalStyled.FieldsRow>

          <UserSettingModalStyled.FieldsRow>
            <FormInputGroup
              {...fieldProps}
              label={t('USER_SETTINGS.GENERAL.LAST_NAME', 'Last Name')}
              name="last_name"
            />
          </UserSettingModalStyled.FieldsRow>

          <UserSettingModalStyled.FieldsRow>
            <FormInputGroup
              {...fieldProps}
              label={t('USER_SETTINGS.GENERAL.NICKNAME', 'Nickname')}
              name="nickname"
            />
          </UserSettingModalStyled.FieldsRow>

          <UserSettingModalStyled.FieldsRow>
            <FormInputGroup
              {...fieldProps}
              label={t('USER_SETTINGS.GENERAL.LOCATION', 'Location')}
              name="location"
            />
          </UserSettingModalStyled.FieldsRow>
        </Box>
      </Box>

      <FormTextareaGroup
        {...fieldProps}
        label={t('USER_SETTINGS.GENERAL.BIO', 'Bio')}
        name="bio"
      />
    </>
  );
}

GeneralForm.propTypes = {
  form: PT.object.isRequired,
  errors: PT.object.isRequired,
  setValue: PT.func.isRequired,
  register: PT.func.isRequired,
  user: PT.object,
  isUserLoading: PT.bool,
  trigger: PT.func.isRequired,
  dirtyFields: PT.object.isRequired,
};

export default GeneralForm;
