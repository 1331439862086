import styled from 'styled-components';
// eslint-disable-next-line max-len
import PersonalDropdownStyled from '../../../../../layout/components/top-nav/components/personal-settings/components/dropdown-content/dropdown-content.styles';


const ImageContainer = styled(PersonalDropdownStyled.ImageContainer)`
  flex-grow: 1;

  .image-field__container {
    width: 190px;
    height: 190px;
  }
`;

const Styled = {
  ImageContainer,
};

export default Styled;
