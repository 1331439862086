import PT from 'prop-types';
import { Textarea, Label, FormHint } from '@scaleflex/ui/core';
import { useFormField } from '../../hooks';
import Styled from './form-textarea-group.styles';


/**
 * Usage:
 *
 * const fieldProps = {
 *   form, errors, setValue, trigger, dirtyFields,
 * };
 *
 * <FormTextareaGroup label="Hint" name="hint" {...fieldProps} />
 */
function FormTextareaGroup({
  name, form, errors, setValue, label, trigger, dirtyFields, ...rest
}) {
  const {
    value, error, hint, onChange, onBlur,
  } = useFormField({
    name, form, errors, setValue, trigger, dirtyFields, hint: rest?.hint,
  });
  const fieldProps = {
    value,
    error,
    onBlur,
    onChange: ({ target: { value: newValue } }) => { onChange(newValue); },
  };

  return (
    <Styled.Container>
      {label && <Label error={error}>{label}</Label>}

      <Textarea
        // fullWidth
        {...rest}
        {...fieldProps}
      />

      {hint && <FormHint error={error}>{hint}</FormHint>}
    </Styled.Container>
  );
}

FormTextareaGroup.propTypes = {
  label: '',
};

FormTextareaGroup.propTypes = {
  name: PT.string.isRequired,
  form: PT.object.isRequired,
  errors: PT.object.isRequired,
  setValue: PT.func.isRequired,
  label: PT.node,
  dirtyFields: PT.object,
  trigger: PT.func,
};

export default FormTextareaGroup;
