import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from '@shared-admin-kit/translation';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import { handleFormError } from '../utils/react-hook-forms';
import { ROLE, SCOPE, EMAIL_REGEX } from '../services/user.service';


function useUserShareForm({ user, shareWithUser, open }) {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  // Important! Data format is not the same for PUT and GET APIs. So we have to adapt
  const defaultValues = useMemo(() => ({
    email_address: user?.email || '',
    level: user?.share_level || ROLE.READONLY,
    scope_type: user?.share_scope || SCOPE.COMPANY,
    scope_projects: user?.share_projects_uuids || [],
  }), [user, open]);
  const {
    register, errors, setValue, reset, watch, handleSubmit, formState, trigger,
  } = useForm({ defaultValues });
  const form = watch();
  const { isDirty: isFormChanged, dirtyFields } = formState;

  useEffect(() => {
    register('email_address', {
      required: t('FIELD_IS_REQUIRED', 'Field is required!'),
      pattern: { value: EMAIL_REGEX, message: t('NOT_EMAIL_FORMAT', 'Entered value does not match email format') },
    });
    register('level');
    register('scope_type');
    register('scope_projects');
  }, [register]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleSetValue = (name, value, config = { shouldDirty: true }) => setValue(name, value, config);

  const handleFormSubmit = (formData) => {
    const { scope_projects: scopeProjects, ...restFormData } = formData;
    const data = { ...restFormData };

    if (restFormData?.scope_type === SCOPE.PROJECT) {
      data.scope_projects = scopeProjects;
    }

    shareWithUser(data);
  };

  return {
    form,
    errors,
    isFormChanged,
    trigger,
    dirtyFields,
    setValue: handleSetValue,
    onSubmit: handleSubmit(handleFormSubmit, handleFormError.bind(null, showMessage)),
  };
}

export {
  useUserShareForm,
};
