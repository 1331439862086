import PT from 'prop-types';
import { InputGroup } from '@scaleflex/ui/core';
import { useFormField } from '../../hooks';
import Styled from './form-input-group.styles';


/**
 * TODO: Refactor all forms with InputGroup components, need to use current component
 */

/**
 * Usage:
 *
 * const fieldProps = {
 *   form, errors, setValue, trigger, dirtyFields,
 * };
 *
 * <FormInputGroup label="Hint" name="hint" {...fieldProps} />
 */
function FormInputGroup({
  name, form, errors, setValue, trigger, dirtyFields, containerStyle, ...rest
}) {
  const {
    value, error, hint, onChange, onBlur,
  } = useFormField({
    name, form, errors, setValue, trigger, dirtyFields, hint: rest?.hint,
  });
  const fieldProps = {
    value,
    error,
    hint,
    onBlur,
    onChange: ({ target: { value: newValue } }) => {
      onChange(rest?.inputProps?.type === 'number' ? +newValue : newValue);
    },
  };

  return (
    <Styled.InputGroupContainer style={containerStyle}>
      <InputGroup
        fullWidth
        {...rest}
        inputProps={{
          autoComplete: rest?.inputProps?.type === 'password' ? 'new-password' : 'off',
          name,
          ...(rest?.inputProps || {}),
        }}
        {...fieldProps}
      />
    </Styled.InputGroupContainer>
  );
}

FormInputGroup.propTypes = {
  name: PT.string.isRequired,
  form: PT.object.isRequired,
  errors: PT.object.isRequired,
  setValue: PT.func.isRequired,
  dirtyFields: PT.object,
  trigger: PT.func,
  containerStyle: PT.object,
};

export default FormInputGroup;
