import { useState } from 'react';
// import PT from 'prop-types';
import { useCompany } from '@shared-admin-kit/core';
import TenancySelector from '../../../../components/tenancy-selector';
import Spinner from '../../../../components/spinner';
import ProjectLabel from './components/project-label';
import Styled from './projects-dropdown.styles';


function ProjectsDropdown() {
  const { activeProject, isCompanyLoading, isProjectLoading } = useCompany();
  const isLoading = isCompanyLoading || isProjectLoading;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(undefined);

  return (
    isLoading ? <Spinner size={12} /> : (
      <Styled.Dropdown>
        <ProjectLabel open={Boolean(anchorEl)} onClick={handleClick}>
          <Styled.ProjectTitle>{activeProject?.name}</Styled.ProjectTitle>
        </ProjectLabel>
        <TenancySelector
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        />
      </Styled.Dropdown>
    )
  );
}

// ProjectsDropdown.defaultProps = {};

// ProjectsDropdown.propTypes = {};

export default ProjectsDropdown;
