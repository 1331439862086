import PT from 'prop-types';
import { Route as RouterRoute } from 'react-router-dom';
import { RouteWrapper } from '@shared-admin-kit/core';


function Route({
  auth, component: Component, extraProps, render, ...rest
}) {
  const renderContent = (matchProps) => {
    const props = { ...matchProps, ...(extraProps || {}) };

    if (typeof render === 'function') {
      return render(props);
    }

    if (Component) {
      return <Component {...props} />;
    }

    return null;
  };

  return (
    <RouterRoute
      {...rest}
      render={(matchProps) => (
        <RouteWrapper>
          {renderContent(matchProps)}
        </RouteWrapper>
      )}
    />
  );
}

Route.defaultValues = {
  auth: false,
  extraProps: {},
};

Route.propTypes = {
  auth: PT.bool,
  component: PT.elementType,
  extraProps: PT.object,
  render: PT.func,
};

export default Route;
