import { useEffect, useState, useMemo } from 'react';
import PT from 'prop-types';
import { useCompany } from '@shared-admin-kit/core';
import { useTranslation /* , T */ } from '@shared-admin-kit/translation';
import { Button } from '@scaleflex/ui/core';
// import { useTheme } from '@scaleflex/ui/theme/hooks';
import RemoveIcon from '@scaleflex/icons/remove';
import EditImageIcon from '@scaleflex/icons/rename';
import FilerobotWidget from '@filerobot/core';
import Explorer from '@filerobot/explorer';
import XHRUpload from '@filerobot/xhr-upload';
import DeleteModal from '../delete-modal';
import Box from '../box';
// import { PC } from '../typography';
import Styled from './image-field.styles';


// TODO: Finish component. For now not clear how it must be.
const widgetModalContainerId = 'wordplex-admin_filerobot-widget-modal';
// const widgetModalContainerIdExtra = 'wordplex-admin_filerobot-widget-modal-extra';
let filerobotWidget;

/**
 * @param {object} props
 * @param {object} [props.uploadParams] - Like : {dir: 'watermarks', opt_force_name: 'some_name'}. Params will be added to upload url '/upload?dir=watermarks&opt_force_name=some_name'.
 */
function ImageField({
  value, onChange, onDelete, uploadParams, triggerId, container, showDeleteBtn, isDeleting, deleteModalTitle,
  deleteBtnText, renderImage, renderChangeBtn, minImageContainerWidth, minImageContainerHeight,
  imageContainerWidth, imageContainerHeight, ...rest
}) {
  const { t, activeLanguage } = useTranslation();
  const { token } = useCompany();
  const containerToken = container || token;
  const uploadQueryParams = Object.keys(uploadParams || {})
    .map((paramName) => `${paramName}=${uploadParams[paramName]}`).join('&');

  const [openDeleteModalState, setOpenDeleteModalState] = useState(false);
  // const theme = useTheme();
  const securityTemplate = useMemo(() => rest?.securityTemplate, [rest?.securityTemplate]);

  useEffect(() => {
    if (containerToken && securityTemplate) {
      filerobotWidget = FilerobotWidget({
        container: containerToken,
        securityTemplateID: securityTemplate,
        restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] },
        locale: activeLanguage || 'en',
      })
        .use(Explorer, {
          target: `#${widgetModalContainerId}`,
          noItemsBrowser: true,
          resetAfterClose: true,
          inline: false,
          width: 600,
          height: 400,
          trigger: `#${triggerId}`, // 'selecor of the change image button that would open modal',
          closeAfterFinish: true,
          closeModalOnClickOutside: true,
        })
        .use(XHRUpload, { uploadQueryParams });

      filerobotWidget.on('complete', (result) => onChange(result.successful[0], result));
    }

    return () => {
      if (filerobotWidget) filerobotWidget.close();
    };
  }, [containerToken, uploadQueryParams, triggerId, securityTemplate, activeLanguage]);


  return (
    <Box display="flex" alignItems="start" className="image-field__container">
      {typeof renderImage === 'function'
        ? renderImage(value)
        : (
          <Styled.ImageContainer
            minWidth={minImageContainerWidth}
            minHeight={minImageContainerHeight}
            width={imageContainerWidth}
            height={imageContainerHeight}
          >
            {value && <Styled.Image src={value} alt="image" {...rest} />}
          </Styled.ImageContainer>
        )}

      <Box className="image-field__actions" flexShrink={0} ml="12px" display="flex">
        {typeof renderChangeBtn === 'function' ? renderChangeBtn({ id: triggerId }) : (
          // <Button color="secondary" size="sm" id={triggerId}>
          //   {value
          //     ? <T i18nKey="CHANGE_IMAGE" defaultValue="Change image" />
          //     : <T i18nKey="ADD_IMAGE" defaultValue="Add image" />}
          // </Button>
          <Button color="link" size="xs" id={triggerId} style={{ padding: 4 }}>
            <EditImageIcon color="#A9B6C2" size={16} style={{ cursor: 'pointer' }} />
          </Button>
        )}

        {showDeleteBtn && (
          <Button
            color="link"
            size="xs"
            onClick={() => setOpenDeleteModalState(true)}
            // style={{ color: theme?.palette?.[PC.Error] }}
            style={{ padding: 4 }}
            loading={isDeleting}
          >
            {deleteBtnText || (
              <RemoveIcon color="#A9B6C2" size={16} />
              /* <T i18nKey="DELETE_IMAGE" defaultValue="Delete image" /> */
            )}
          </Button>
        )}
      </Box>

      <DeleteModal
        open={openDeleteModalState}
        title={deleteModalTitle || `${t('DELETE_IMAGE', 'Delete image')}?`}
        message=""
        onClose={() => setOpenDeleteModalState(false)}
        onDelete={onDelete}
      />
    </Box>
  );
}

ImageField.defaultProps = {
  value: null,
  container: null,
  securityTemplate: null,
  // triggerId: 'filerobot-widget-trigger',
  uploadParams: {},
  showDeleteBtn: false,
  isDeleting: false,
  onDelete: () => {},
  // deleteBtnText: '', // Delete image
  // deleteModalTitle: '', // Delete image?
  renderImage: null,
};

ImageField.propTypes = {
  value: PT.string,
  triggerId: PT.string.isRequired,
  container: PT.string,
  securityTemplate: PT.string,
  onChange: PT.func.isRequired,
  onDelete: PT.func,
  uploadParams: PT.objectOf(PT.string),
  showDeleteBtn: PT.bool,
  isDeleting: PT.bool,
  deleteBtnText: PT.node,
  deleteModalTitle: PT.string,
  renderImage: PT.func,
  renderChangeBtn: PT.func,
  minImageContainerWidth: PT.number,
  minImageContainerHeight: PT.number,
  imageContainerWidth: PT.number,
  imageContainerHeight: PT.number,
};

export default ImageField;

export {
  widgetModalContainerId,
  // widgetModalContainerIdExtra,
};
