import uuidJs from 'uuidjs';


const STORAGE_NAME = 'global-notifications';

function useGlobalNotificationsStorage({ defaultNotificationLifeSpanSec = 180 }) {
  const encode = (arr) => JSON.stringify(arr);
  const decode = (str) => {
    try {
      const arr = JSON.parse(str);
      if (Array.isArray(arr)) { return arr; }
    // eslint-disable-next-line no-empty
    } catch (error) {}

    return [];
  };
  const save = (arr) => sessionStorage.setItem(STORAGE_NAME, encode(arr));
  const getList = () => {
    const notifications = decode(sessionStorage.getItem(STORAGE_NAME));
    const freshNotifications = notifications.filter((item) => (item.expireTimestamp > Date.now()));

    save(freshNotifications);

    return freshNotifications;
  };
  const has = (notification) => getList().some((item) => item.notification === notification);
  const find = (notification) => getList().find((item) => item.notification === notification);
  const updateTimestamp = (uuid, lifeSpanSec = defaultNotificationLifeSpanSec) => {
    const newNotifications = [...getList()];
    const itemIndex = newNotifications.findIndex((item) => item.uuid === uuid);

    if (itemIndex > -1) { newNotifications[itemIndex].expireTimestamp = Date.now() + lifeSpanSec * 1000; }
    save(newNotifications);

    return newNotifications;
  };
  const add = (notification, lifeSpanSec = defaultNotificationLifeSpanSec) => {
    if (!notification) { return getList(); }

    const existItem = find(notification);
    if (existItem) { return updateTimestamp(existItem.uuid, lifeSpanSec); }

    const newNotifications = [
      ...getList(),
      { uuid: uuidJs.generate(), notification, expireTimestamp: Date.now() + lifeSpanSec * 1000 },
    ];
    save(newNotifications);

    return newNotifications;
  };
  const remove = (uuid) => {
    const newNotifications = [...getList()];
    const itemIndex = newNotifications.findIndex((item) => item.uuid === uuid);

    if (itemIndex > -1) { newNotifications.splice(itemIndex, 1); }
    save(newNotifications);

    return newNotifications;
  };
  const clean = () => sessionStorage.removeItem(STORAGE_NAME);

  return {
    getList,
    add,
    remove,
    clean,
    find,
    has,
    updateTimestamp,
  };
}

export { useGlobalNotificationsStorage };
