const NAMESPACE = 'js-admin-react-i18n/available-languages';

const SET_LANGUAGE_LIST = `${NAMESPACE}/SET_LANGUAGE_LIST`;
const ADD_LANGUAGE_ITEM = `${NAMESPACE}/ADD_LANGUAGE_ITEM`;


const initialState = {
  languages: [],
  isLoaded: false,
};

const availableLanguagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_LIST:
      return {
        isLoaded: true,
        languages: action.languages,
      };
    case ADD_LANGUAGE_ITEM:
      return {
        ...state,
        languages: [
          ...state.languages,
          action.language,
        ],
      };
    default:
      return state;
  }
};

export {
  SET_LANGUAGE_LIST,
  ADD_LANGUAGE_ITEM,
};

export default availableLanguagesReducer;
