import queryString from 'query-string';
import { SCALEFLEX_CLOUDIMG_URL } from '../global.constants';


/**
 * @param {string} originalUrl
 * @param {object} params - Like: {w: 300, h: 300}
 * @returns {string}
 */
function getCloudimageV7Url(originalUrl, params = {}) {
  if (originalUrl) {
    const { url, query: oldQuery } = queryString.parseUrl(originalUrl || '');
    const query = { ...oldQuery, ...params };
    const alreadyHasPrefix = (new RegExp(`^${SCALEFLEX_CLOUDIMG_URL}`)).test(url);

    return `${alreadyHasPrefix ? '' : SCALEFLEX_CLOUDIMG_URL}${queryString.stringifyUrl({ url: alreadyHasPrefix ? url : url.replace(/^\/\//, 'https://'), query })}`;
  }

  return '';
}


export {
  getCloudimageV7Url,
};
