import { Size } from '@scaleflex/ui/core/modal/types';
import PT from 'prop-types';
import { useTranslation } from '@shared-admin-kit/translation';
import { useModalTabs } from '../../hooks';
import StyledModal from '../styled-modal';
import StyledModalTitle from '../styled-modal-title';
import StyledModalContent from '../styled-modal-content';
import StyledModalTabs from '../styled-modal-tabs';
import { TABS } from './user-settings-modal.constants';


function UserSettingsModal({ open, onClose }) {
  const {
    activeTabIndex, setActiveTabIndex, tabs, tabsPanels,
  } = useModalTabs(TABS, { onClose, open });
  const handleTabClick = (newValue) => setActiveTabIndex(newValue);
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <StyledModal
      fullWidth
      maxWidth={Size.Sm}
      open={open}
      onClose={onClose}
      style={{ height: '600px', maxWidth: '500px' }}
    >
      <StyledModalTitle
        primary={t('USER_SETTINGS', 'User Settings')}
        onClose={onClose}
      />

      <StyledModalContent>
        <StyledModalTabs
          value={activeTabIndex}
          onChange={handleTabClick}
          size="lg"
        >
          {tabs}
        </StyledModalTabs>

        {tabsPanels}
      </StyledModalContent>
    </StyledModal>
  );
}

UserSettingsModal.defaultProps = {
  open: false,
  onClose: () => {},
};

UserSettingsModal.propTypes = {
  open: PT.bool,
  onClose: PT.func,
};

export default UserSettingsModal;
