import PT from 'prop-types';
import { Loader } from '@scaleflex/ui/core';
import Styled from './main-loader.styles';


function MainLoader({ container, ...rest }) {
  if (container) {
    return (
      <Styled.Container {...rest}>
        <Loader />
      </Styled.Container>
    );
  }

  return (
    <Loader {...rest} />
  );
}

MainLoader.defaultProps = {
  container: true,
};

MainLoader.propTypes = {
  container: PT.bool,
};

export default MainLoader;
