/**
 * Useful for array fields, when we need to show in input field array comma separated values.
 *
 * For ex. form.languages=["FR", "EN"], we show input field with converted to string value "FR, EN",
 * current function help to convert changed string value back to array, like "FR, EN, DE" -> ["FR", "EN", "DE"].
 * In this case all the time we have correct field value type (array).
 *
 * register('languages', { setValueAs: valueAsArray });
 *
 * <input value={(form.languages || []).join(',')}
 */
const valueAsArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  }

  if (!value || Array.isArray(value)) {
    return [];
  }

  return value.split(',')
    .map((item) => (item || '').trim());
};

export {
  valueAsArray,
};
