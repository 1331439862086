import PT from 'prop-types';
import { objectValues, intrinsicComponent } from '@scaleflex/ui/utils/functions';
import { Color as PaletteColor } from '@scaleflex/ui/utils/types/palette/color';
import { FontVariant } from '@scaleflex/ui/utils/types/typography/font-variant';
import { Variant } from './types';
import Styled from './typography.styles';


const Typography = intrinsicComponent((props, ref) => <Styled.Typography ref={ref} {...props} />);

Typography.defaultProps = {
  variant: Variant.Body,
  noWrap: false,
};

Typography.propTypes = {
  variant: PT.oneOf(objectValues(Variant)),
  fontVariant: PT.oneOf(objectValues(FontVariant)),
  colorVariant: PT.oneOf(objectValues(PaletteColor)),
  noWrap: PT.bool,
};

// Aliases
const PC = PaletteColor;
const FV = FontVariant;

export default Typography;

export {
  PaletteColor,
  PC,
  FontVariant,
  FV,
};
