import PT from 'prop-types';
import RemoveIcon from '@scaleflex/icons/remove';
import { useTheme } from '@scaleflex/ui/theme/hooks';
import {
  Modal, ModalTitle, ModalContent, ModalActions, Button,
} from '@scaleflex/ui/core';
import { T } from '@shared-admin-kit/translation';
import { PC } from '../typography';

/**
 * TODO: Refactor all same delete modals, and use this shared component
 */

function DeleteModal({
  open, onClose, onDelete, title, message, deleteBtnText, isDeleting,
}) {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={onClose} fullWidth style={{ minWidth: 'max-content' }}>
      <ModalTitle
        primary={title || <T i18nKey="DELETE" defaultValue="Delete" />}
        icon={<RemoveIcon size={25} color={theme.palette[PC.Error]} />}
        variant="with-icon"
      />
      {
        message && (
          <ModalContent style={{ textAlign: 'center' }}>
            {message}
          </ModalContent>
        )
      }
      <ModalActions>
        <Button
          color="link"
          onClick={onClose}
        >
          <T i18nKey="CANCEL" defaultValue="Cancel" />
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onDelete();
            onClose();
          }}
          style={{ background: 'rgba(232,91,70,1)' }}
          loading={isDeleting}
        >
          {deleteBtnText || <T i18nKey="DELETE" defaultValue="Delete" />}
        </Button>
      </ModalActions>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  open: false,
  isDeleting: false,
  onClose: () => {},
};

DeleteModal.propTypes = {
  open: PT.bool,
  title: PT.node,
  deleteBtnText: PT.node,
  message: PT.node,
  onClose: PT.func,
  onDelete: PT.func.isRequired,
  isDeleting: PT.bool,
};

export default DeleteModal;
