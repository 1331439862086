import { useState, useMemo } from 'react';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import { useTranslation } from '@shared-admin-kit/translation';
import {
  DEEPL_AVAILABLE_SOURCE_LANGUAGES, getTranslationsRequest, MAX_TRANSLATIONS_LIMIT_PER_REQUEST,
  DeeplError, DEEPL_ERROR_TYPE,
} from '../services/api/deepl-translations.service';
import { updateGridItemsMultipleRequest } from '../services/api/grid.service';


const encodeText = (text) => {
  let encodedText = text || '';
  const varsMatches = [...(text || '').matchAll(/\{[^{}]+?\}/gm)];
  const variables = varsMatches.map((item) => item?.[0]);

  varsMatches.reverse().forEach((item) => {
    encodedText = `${encodedText.substring(0, item.index)}{{}}${encodedText.substring(item.index + item?.[0].length)}`;
  });

  return { encodedText, variables };
};

const decodeText = (text, variables = []) => {
  let decodedText = text || '';
  const varsFillerMatches = [...(text || '').matchAll(/{{}}/gm)];
  const reversedVariables = [...(variables || [])].reverse();

  varsFillerMatches.reverse().forEach((item, index) => {
    decodedText = `${decodedText.substring(0, item.index)}${
      reversedVariables[index]}${decodedText.substring(item.index + item?.[0].length)}`;
  });

  return decodedText;
};

/**
 * Function to figure out which translations we need to handle
 */
const shouldGridItemBeTranslated = ({ gridItem, targetLang, sourceLang }) => {
  const sourceText = gridItem?.translations?.[sourceLang];
  const targetText = gridItem?.translations?.[targetLang];

  // Here is logic where we decide which texts should be translated

  if (!sourceText) { return false; } // Skip translation when source text (for ex: "en") is missing
  if (targetText && sourceText !== targetText) { return false; } // Skip translation when target text (for ex: "fr") already exists and is different than source (en)
  if (!/[a-z]/gim.test(sourceText)) { return false; } // Don't need to translate not words texts, like version "1.2.3"

  return true;
};

const prepareGridData = ({
  sourceGridData, targetLang, sourceLang, forceWithMaxLimit,
}) => {
  let counter = 0;

  return (sourceGridData || [])
    .filter((gridItem) => {
      if (forceWithMaxLimit && counter >= MAX_TRANSLATIONS_LIMIT_PER_REQUEST) {
        return false;
      }

      const enableDeeplTranslation = shouldGridItemBeTranslated({ gridItem, targetLang, sourceLang });

      if (enableDeeplTranslation) {
        counter += 1;
      }

      return enableDeeplTranslation;
    })
    .map((gridItem) => {
      const sourceText = gridItem.translations[sourceLang];
      const { encodedText: encodedSourceText, variables: sourceVariables } = encodeText(sourceText);

      return ({
        ...gridItem,
        deepl: {
          enable: true,
          sourceText,
          encodedSourceText,
          text: sourceText,
          sourceVariables,
        },
      });
    });
};

export function useDeeplTranslations({ sourceLang, gridUuid, refreshGrid }) {
  const { showMessage } = useSnackbar();
  const { t } = useTranslation();
  const [deeplState, setDeeplState] = useState({
    isTranslationMode: false,
    gridData: [], // Actual grid items that will be, or already translated
    targetLang: null,
    isLoading: false,
  });
  const isDeeplTranslationsEnabled = useMemo(() => (
    Boolean(sourceLang && DEEPL_AVAILABLE_SOURCE_LANGUAGES.includes(sourceLang.toUpperCase()))
  ), [sourceLang]);

  const updateState = (changes = {}) => {
    setDeeplState((_state) => ({
      ..._state,
      ...(changes || {}),
    }));
  };

  const runTranslation = (sourceGridData, targetLang, { forceWithMaxLimit = false } = {}) => {
    // eslint-disable-next-line arrow-body-style
    const handleError = (error) => {
      updateState({ isLoading: false });
      return Promise.reject(error);
    };

    updateState({
      isLoading: true,
      isTranslationMode: false, // default value
      targetLang,
    });

    if (!sourceGridData) {
      const errorMsg = t('SOURCE_GRID_DATA_IS_REQUIRED', 'sourceGridData is required!');
      showMessage(errorMsg, { variant: 'error' });
      return handleError(new Error(errorMsg));
    }
    if (!targetLang) {
      const errorMsg = t('TARGET_LANG_IS_REQUIRED', 'targetLang is required!');
      showMessage(errorMsg, { variant: 'error' });
      return handleError(new Error(errorMsg));
    }
    if (!sourceLang) {
      const errorMsg = t('SOURCE_LANG_IS_REQUIRED', 'sourceLang is required!');
      showMessage(errorMsg, { variant: 'error' });
      return handleError(new Error(errorMsg));
    }

    const gridData = prepareGridData({
      sourceGridData, targetLang, sourceLang, forceWithMaxLimit,
    });

    updateState({ gridData });

    const text = gridData
      // .filter((gridItem) => gridItem?.deepl?.enable)
      .map((gridItem) => gridItem.deepl.encodedSourceText);

    if (text.length > MAX_TRANSLATIONS_LIMIT_PER_REQUEST) {
      return handleError(new DeeplError(`Translations limit ${MAX_TRANSLATIONS_LIMIT_PER_REQUEST}`, {
        type: DEEPL_ERROR_TYPE.MAX_TRANSLATIONS_LIMIT,
        textCount: text.length,
      }));
    }

    return getTranslationsRequest({
      text,
      targetLang,
      sourceLang,
    })
      .then((response) => {
        const deeplTranslations = response?.data?.translations || [];
        const newGridData = gridData.map((item, index) => ({
          ...item,
          deepl: {
            ...item.deepl,
            text: decodeText(deeplTranslations?.[index]?.text, item.deepl.sourceVariables),
          },
        }));

        updateState({ isTranslationMode: true, gridData: newGridData });
      })
      .catch((error) => showMessage(error?.message, { variant: 'error' }))
      .finally(() => updateState({ isLoading: false }));
  };

  const cancelTranslations = () => {
    updateState({
      isTranslationMode: false,
      gridData: [],
      targetLang: null,
      isLoading: false,
    });
  };

  const revertTranslationText = (translationKey) => {
    const newGridData = [...deeplState.gridData].filter((item) => item?.translation_key !== translationKey);

    updateState({ gridData: newGridData });
  };

  const editTranslationText = (translationKey, newValue) => {
    const newGridData = [...deeplState.gridData].map((item) => {
      if (item?.translation_key === translationKey) {
        return { ...item, deepl: { ...item?.deepl, text: newValue } };
      }

      return item;
    });

    updateState({ gridData: newGridData });
  };

  const saveDeeplTranslations = () => {
    const translations = deeplState?.gridData
      .filter((item) => item?.deepl?.enable)
      .filter((item) => item.translations?.[deeplState?.targetLang] !== item?.deepl?.text)
      .map((item) => ({
        key: item.translation_key,
        langs: {
          ...(item.translations || {}),
          [deeplState?.targetLang]: item?.deepl?.text,
        },
      }));

    if (translations.length === 0) {
      return cancelTranslations();
    }

    updateState({ isSaving: true });
    return updateGridItemsMultipleRequest(gridUuid, translations)
      .then(() => {
        showMessage('Translations saved succesfully!');
        cancelTranslations();
        refreshGrid();
      })
      .catch((error) => {
        showMessage(error?.message, { variant: 'error' });
      })
      .finally(() => updateState({ isSaving: false }));
  };

  return {
    isDeeplTranslationsEnabled,
    runTranslation,
    deeplState,
    cancelTranslations,
    revertTranslationText,
    editTranslationText,
    saveDeeplTranslations,
  };
}
