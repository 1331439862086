import styled, { css } from 'styled-components';
import { ModalTitle as SfxModalTitle } from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const StyledModalTitle = styled(SfxModalTitle)(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['lable-lg']}
    background: ${palette[PC.BackgroundSecondary]};
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    flex-shrink: 0;
    color: ${palette[PC.TextPrimary]};

    .SfxModalTitle-Close {
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
      color: ${palette[PC.IconsSecondary]};

      svg {
        width: 16px;
        height: 16px;
      }
    }
  `,
);

export default StyledModalTitle;
