import styled from 'styled-components';
// import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const ImageContainer = styled(({
  minWidth, minHeight, width, height, ...rest
}) => <div {...rest} />)((
  ({
    /* theme: { palette }, */ minWidth = 32, minHeight = 32, width, height,
  }) => `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${minWidth}px;
    min-height: ${minHeight}px;
    ${width ? `width: ${width}px;` : ''}
    ${height ? `height: ${height}px;` : ''}
    background: #DFE7ED;
  `
));

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Styled = {
  ImageContainer,
  Image,
};

export default Styled;
