import { useState, forwardRef } from 'react';
import PT from 'prop-types';
import Arrow from '@scaleflex/ui/core/arrow';
import Menu from '@scaleflex/ui/core/menu';
import Styled from './dropdown.styles';


const Dropdown = forwardRef(({
  children, label, showArrow, dropdownStyle, ...rest
}, ref) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(undefined);

  return (
    <>
      <Styled.Dropdown onClick={handleClick} ref={ref} style={dropdownStyle}>
        <Styled.DropdownLabel title={typeof label === 'string' ? label : undefined}>
          {label}
        </Styled.DropdownLabel>

        {
          showArrow
            && (
            <Styled.DropdownIcon>
              <Arrow IconProps={{ size: 7 }} type={anchorEl ? 'top' : 'bottom'} />
            </Styled.DropdownIcon>
            )
        }
      </Styled.Dropdown>

      {Boolean(anchorEl || rest.anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl || rest.anchorEl)}
          onClose={handleClose}
          enableOverlay
          {...rest}
        >
          {typeof children === 'function'
            ? children({
              onClose: handleClose,
            })
            : children}
        </Menu>
      )}
    </>
  );
});

Dropdown.defaultProps = {
  children: null,
  showArrow: true,
};

Dropdown.propTypes = {
  label: PT.node,
  children: PT.oneOfType([PT.node, PT.func]),
  showArrow: PT.bool,
  dropdownStyle: PT.object,
};

export default Dropdown;
