import PT from 'prop-types';


const PropNameType = {
  Simple: 'simple',
  Spacing: 'spacing',
  Size: 'size',
};

const PropName = {
  // Simple
  Position: 'position',
  Display: 'display',
  AlignItems: 'alignItems',
  JustifyContent: 'justifyContent',
  FlexShrink: 'flexShrink',
  FlexGrow: 'flexGrow',
  FlexDirection: 'flexDirection',
  FlexWrap: 'flexWrap',
  FlexBasis: 'flexBasis',
  Flex: 'flex',
  Opacity: 'opacity',
  TextAlign: 'textAlign',
  FontWeight: 'fontWeight',
  BoxSizing: 'boxSizing',
  Overflow: 'overflow',
  OverflowX: 'overflowX',
  OverflowY: 'overflowY',
  Color: 'color',
  Background: 'background',
  BackgroundColor: 'backgroundColor',
  Cursor: 'cursor',
  LineHeight: 'lineHeight',

  // Size
  Width: 'width',
  Height: 'height',
  MinWidth: 'minWidth',
  MinHeight: 'minHeight',
  MaxWidth: 'maxWidth',
  MaxHeight: 'maxHeight',
  FontSize: 'fontSize',

  // Spacing
  M: 'm',
  Mt: 'mt',
  Mr: 'mr',
  Mb: 'mb',
  Ml: 'ml',
  Mx: 'mx',
  My: 'my',
  P: 'p',
  Pt: 'pt',
  Pr: 'pr',
  Pb: 'pb',
  Pl: 'pl',
  Px: 'px',
  Py: 'py',
};

const generateSimpleDetail = (stylePropName, options) => ({
  stylePropName,
  type: PropNameType.Simple,
  propType: PT.string,
  ...(options || {}),
});

const generateSpacingDetail = (stylePropName, options) => ({
  stylePropName,
  type: PropNameType.Spacing,
  propType: PT.oneOfType([PT.number, PT.string]),
  ...(options || {}),
});

const generateSizeDetail = (stylePropName, options) => ({
  stylePropName,
  type: PropNameType.Size,
  propType: PT.oneOfType([PT.number, PT.string]),
  ...(options || {}),
});

const PropNameDetail = {
  // Simple:
  [PropName.Position]: generateSimpleDetail('position'),
  [PropName.Display]: generateSimpleDetail('display'),
  [PropName.AlignItems]: generateSimpleDetail('align-items'),
  [PropName.JustifyContent]: generateSimpleDetail('justify-content'),
  [PropName.FlexDirection]: generateSimpleDetail('flex-direction'),
  [PropName.FlexShrink]: generateSimpleDetail('flex-shrink', { propType: PT.oneOfType([PT.number, PT.string]) }),
  [PropName.FlexGrow]: generateSimpleDetail('flex-grow', { propType: PT.oneOfType([PT.number, PT.string]) }),
  [PropName.FlexWrap]: generateSimpleDetail('flex-wrap'),
  [PropName.FlexBasis]: generateSimpleDetail('flex-basis'),
  [PropName.Flex]: generateSimpleDetail('flex', { propType: PT.oneOfType([PT.number, PT.string]) }),
  [PropName.Opacity]: generateSimpleDetail('opacity', { propType: PT.oneOfType([PT.number, PT.string]) }),
  [PropName.TextAlign]: generateSimpleDetail('text-align'),
  [PropName.FontWeight]: generateSimpleDetail('font-weight'),
  [PropName.BoxSizing]: generateSimpleDetail('box-sizing'),
  [PropName.Overflow]: generateSimpleDetail('overflow'),
  [PropName.OverflowX]: generateSimpleDetail('overflow-x'),
  [PropName.OverflowY]: generateSimpleDetail('overflow-y'),
  [PropName.Color]: generateSimpleDetail('color'),
  [PropName.Background]: generateSimpleDetail('background'),
  [PropName.BackgroundColor]: generateSimpleDetail('background-color'),
  [PropName.Cursor]: generateSimpleDetail('cursor'),
  [PropName.LineHeight]: generateSimpleDetail('line-height'),

  // Size:
  [PropName.Width]: generateSizeDetail('width'),
  [PropName.Height]: generateSizeDetail('height'),
  [PropName.MinWidth]: generateSizeDetail('min-width'),
  [PropName.MinHeight]: generateSizeDetail('min-height'),
  [PropName.MaxWidth]: generateSizeDetail('max-width'),
  [PropName.MaxHeight]: generateSizeDetail('max-height'),
  [PropName.FontSize]: generateSizeDetail('font-size'),

  // Spacing:
  [PropName.M]: generateSpacingDetail('margin'),
  [PropName.Mt]: generateSpacingDetail('margin-top'),
  [PropName.Mr]: generateSpacingDetail('margin-right'),
  [PropName.Mb]: generateSpacingDetail('margin-bottom'),
  [PropName.Ml]: generateSpacingDetail('margin-left'),
  [PropName.Mx]: generateSpacingDetail(['margin-left', 'margin-right']),
  [PropName.My]: generateSpacingDetail(['margin-top', 'margin-bottom']),
  [PropName.P]: generateSpacingDetail('padding'),
  [PropName.Pt]: generateSpacingDetail('padding-top'),
  [PropName.Pr]: generateSpacingDetail('padding-right'),
  [PropName.Pb]: generateSpacingDetail('padding-bottom'),
  [PropName.Pl]: generateSpacingDetail('padding-left'),
  [PropName.Px]: generateSpacingDetail(['padding-left', 'padding-right']),
  [PropName.Py]: generateSpacingDetail(['padding-top', 'padding-bottom']),
};

export const propNamePropTypes = Object.keys(PropNameDetail)
  .reduce(
    (accumulator, currentPropName) => {
      const detail = PropNameDetail[currentPropName];
      const propType = detail && detail.propType ? detail.propType : PT.any;

      accumulator[currentPropName] = propType;

      return accumulator;
    },
    {},
  );

export {
  PropNameType,
  PropName,
  PropNameDetail,
};
