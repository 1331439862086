import { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import { FontVariant as FV } from '@scaleflex/ui/utils/types/typography/font-variant';
import { Variant } from './types';


export const variantMixin = {
  [Variant.Title]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.LabelExtraLarge]}
    color: ${`${palette[PC.TextPrimary]}`};
  `,

  [Variant.Title2]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.LabelExtraLarge]}
    color: ${`${palette[PC.TextSecondary]}`};
    font-weight: normal;
  `,

  [Variant.Subtitle]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.ButtonMd]}
    color: ${`${palette[PC.TextPrimary]}`};
  `,

  [Variant.Subtitle2]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.ButtonMd]}
    color: ${`${palette[PC.TextSecondary]}`};
    font-weight: normal;
  `,

  [Variant.Body]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.TextNormal]}
    color: ${`${palette[PC.TextPrimary]}`};
  `,

  [Variant.LabelSm]: ({ theme: { palette, typography: { font } } }) => css`
    ${font[FV.LabelSmall]}
    color: ${`${palette[PC.TextSecondary]}`};
  `,
};
