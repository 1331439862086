import { client } from './client';


export function createGridRequest(grid) {
  return client.post('/grid', { grid });
}

export function renameGridRequest(gridUuid, value) {
  return client.put(`/grid/${gridUuid}`, {
    grid: {
      name: value.name,
      sourceLanguage: value.srcLang,
      targetLanguages: value.targetLangs,
    },
  });
}

export function deleteGridRequest(gridUuid) {
  return client.delete(`/grid/${gridUuid}`);
}

export function getGridTranslationsRequest(gridUuid) {
  // return client.get(`/export-translations-temp-3?grid=${gridUuid}`);
  return client.get(`/grids/${gridUuid}/translations`);
}

export function deleteGridKeysRequest(gridUuid, keys = []) {
  return client.delete(`/grid/${gridUuid}/keys`, { data: { keys } });
}

export function addGridItemRequest(gridUuid, key) {
  return client.post('/translation', {
    translation: {
      translation_key: key,
      bucket_uuid: gridUuid,
      sub_bucket_uuid: null,
      language_uuid: null,
      bucket_schema_uuid: null,
      text: null,
      edit_key: true,
    },
  });
}

/**
 * @param {string} gridUuid
 * @param {{key: string; langs: object}[]} translations
 * @returns
 */
export function updateGridItemsMultipleRequest(gridUuid, translations) {
  return client.put(`/v3/bucket/${gridUuid}/translations`, { translations });
}

export function updateGridItemRequest(gridUuid, key, langs) {
  return updateGridItemsMultipleRequest(gridUuid, [{ key, langs }]);
}
