import dotProp from 'dot-prop-immutable';


/**
 * Use dotProp, but for get method we change path to support array with format: 'foo[0].bar'.
 * With dotPropWithArray we can use both formats:
 *  - dotPropWithArray.get({...}, 'foo[0].bar')
 *  - dotPropWithArray.get({...}, 'foo.0.bar')
 *
 * dotProp by default support only 'foo.0.bar'.
 * Useful for react-hook-form because it use format 'foo[0].bar'
 */
const dotPropWithArray = {
  ...dotProp,
  get: (source, path, ...rest) => dotProp.get(source, (path || '').replace(/\[(\w+)\]/g, '.$1'), ...rest),
};

export {
  dotPropWithArray,
};
