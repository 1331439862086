import { useState, useMemo } from 'react';
import { useTranslation } from '@shared-admin-kit/translation';
import getTabsWithPanels from '../utils/get-tabs-with-panels';


/**
 * @param {object} options
 * @param {{name: string, nameEnd: React.Node, Icon: React.Node, Component: React.Node}[]} options.tabs
 * @param {object} options.TabPanelComponentProps
 * @param {object} options.TabPanelProps
 */
function useTabs(options) {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, tabsPanels] = useMemo(
    () => getTabsWithPanels(
      (options?.tabs || []).map((tab) => ({
        ...tab,
        TabPanelProps: {
          ...tab.TabPanelProps,
          ...options?.TabPanelProps,
        },
        TabPanelComponentProps: {
          ...tab.TabPanelComponentProps,
          ...options?.TabPanelComponentProps,
        },
      })),
      activeTabIndex,
      { t },
    ),
    [activeTabIndex, options?.tabs, options?.TabPanelComponentProps, options?.TabPanelProps],
  );

  return {
    activeTabIndex,
    setActiveTabIndex,
    tabs,
    tabsPanels,
  };
}

export {
  useTabs,
};
