import { Button } from '@scaleflex/ui/core';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import PT from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '@shared-admin-kit/core';
import { useTranslation, T } from '@shared-admin-kit/translation';
import { setUserMetaRequest } from '@shared-admin-kit/core/lib/modules/auth/auth.service';
import { handleFormError } from '../../../../utils/react-hook-forms';
import StyledModalActions from '../../../styled-modal-actions';
import TabPanelWrapper, { TabPanelContainer } from '../../../tab-panel-wrapper';
import GeneralForm from './components/general-form';


function GeneralTab({ onClose }) {
  const { t } = useTranslation();
  const { updateSession, user, isUserLoading } = useAuth();
  const { showMessage } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  const defaultValues = useMemo(() => ({
    first_name: user?.infos?.first_name || '',
    last_name: user?.infos?.last_name || '',
    photo_uri: user?.infos?.photo_uri || '',
    nickname: user?.infos?.nickname || '',
    location: user?.infos?.location || '',
    bio: user?.infos?.bio || '',
  }), [user]);

  const {
    register,
    errors,
    setValue,
    reset,
    watch,
    handleSubmit,
    trigger,
    formState,
  } = useForm({ defaultValues });
  const form = watch();
  const { isDirty: isFormChanged, dirtyFields } = formState;

  useEffect(() => {
    updateSession(false, false);
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleSetValue = (name, value, config = { shouldDirty: true }) => setValue(name, value, config);

  const handleFormSubmit = (formData) => {
    setIsSaving(true);
    return setUserMetaRequest('ROOT_REPLACE', formData)
      .then(() => {
        showMessage(t('UPDATED_SUCCESSFULLY', 'Updated successfully!'));
        return updateSession(false, false);
      })
      .catch(() => showMessage(t('SAVING_ERROR', 'Saving error!'), { variant: 'error' }))
      .finally(() => { setIsSaving(false); });
  };

  return (
    <TabPanelContainer>
      <TabPanelWrapper>
        <GeneralForm
          form={form}
          errors={errors}
          register={register}
          setValue={handleSetValue}
          trigger={trigger}
          dirtyFields={dirtyFields}
          user={user}
          isUserLoading={isUserLoading}
        />
      </TabPanelWrapper>

      <StyledModalActions>
        {isFormChanged
          ? (
            <>
              <Button
                onClick={onClose}
                color="link"
                size="lg"
              >
                <T i18nKey="CANCEL" defaultValue="Cancel" />
              </Button>

              <Button
                onClick={handleSubmit(handleFormSubmit, handleFormError.bind(null, showMessage))}
                color="primary"
                size="lg"
                loading={isSaving}
              >
                <T i18nKey="SAVE_CHANGES" defaultValue="Save Changes" />
              </Button>
            </>
          )
          : (
            <Button
              onClick={onClose}
              color="primary"
              size="lg"
            >
              <T>Done</T>
            </Button>
          )}
      </StyledModalActions>
    </TabPanelContainer>
  );
}

GeneralTab.defaultProps = {
  onClose: () => {},
};

GeneralTab.propTypes = {
  onClose: PT.func,
};

export default GeneralTab;
