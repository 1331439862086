/**
 * Copied from Widget (@filerobot/utils/lib/humanReadableDate)
 * @param {Date} date
 * @param {object} [translations] - Object with translations. Useful for external usage, for example for Hub.
 * @returns {string} - For example: "18 hours ago"
 */
function humanReadableDate(
  date,
  translations = {
    ago: 'ago',
    second: 'second',
    seconds: 'seconds',
    minute: 'minute',
    minutes: 'minutes',
    hour: 'hour',
    hours: 'hours',
    day: 'day',
    days: 'days',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
  },
) {
  function _timeAgo(interval, periodMultiple, periodSingle) {
    return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? periodMultiple : periodSingle} ${translations.ago}`;
  }

  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) return _timeAgo(interval, translations.years, translations.year);

  interval = seconds / 2592000;
  if (interval > 1) return _timeAgo(interval, translations.months, translations.month);

  interval = seconds / 86400;
  if (interval > 1) return _timeAgo(interval, translations.days, translations.day);

  interval = seconds / 3600;
  if (interval > 1) return _timeAgo(interval, translations.hours, translations.hour);

  interval = seconds / 60;
  if (interval > 1) return _timeAgo(interval, translations.minutes, translations.minute);

  return _timeAgo(interval, translations.seconds, translations.second);
}


function timeAgo(dateString, t) {
  const timeAgoTranslations = {
    ago: t('TIME_AGO.AGO', 'ago'),
    second: t('TIME_AGO.SECOND', 'second'),
    seconds: t('TIME_AGO.SECONDS', 'seconds'),
    minute: t('TIME_AGO.MINUTE', 'minute'),
    minutes: t('TIME_AGO.MINUTES', 'minutes'),
    hour: t('TIME_AGO.HOUR', 'hour'),
    hours: t('TIME_AGO.HOURS', 'hours'),
    day: t('TIME_AGO.DAY', 'day'),
    days: t('TIME_AGO.DAYS', 'days'),
    month: t('TIME_AGO.MONTH', 'month'),
    months: t('TIME_AGO.MONTHS', 'months'),
    year: t('TIME_AGO.YEAR', 'year'),
    years: t('TIME_AGO.YEARS', 'years'),
  };

  return humanReadableDate(new Date(dateString), timeAgoTranslations);
}

export { timeAgo };
