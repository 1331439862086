import { useState, useEffect } from 'react';
import PT from 'prop-types';
import WarningIcon from '@scaleflex/icons/warning';
import CrossIcon from '@scaleflex/icons/cross';
import { useGlobalNotifications } from '../../providers/global-notifications-provider';
import Styled from './global-notification.styles';


function GlobalNotification({ uuid, notification }) {
  const { removeNotification, animationDuration } = useGlobalNotifications();
  const [showRemoveAnimation, setShowRemoveAnimation] = useState(false);
  const [showAddAnimation, setShowAddAnimation] = useState(true);

  useEffect(() => {
    setShowAddAnimation(true);
    const timer = setTimeout(() => setShowAddAnimation(false), animationDuration);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Styled.Container
      animationDuration={animationDuration}
      className={`${showRemoveAnimation ? 'is-removing' : ''}${showAddAnimation ? 'is-adding' : ''}`}
      onClick={() => {
        removeNotification(uuid);
        setShowRemoveAnimation(true);
      }}
    >
      <Styled.Icon><WarningIcon size={16} /></Styled.Icon>
      <Styled.Notification>{notification}</Styled.Notification>
      <Styled.Icon><CrossIcon size={12} /></Styled.Icon>
    </Styled.Container>
  );
}

GlobalNotification.propTypes = {
  uuid: PT.string.isRequired,
  notification: PT.string.isRequired,
};

export default GlobalNotification;
