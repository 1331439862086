import styled, { css } from 'styled-components';
import { InputGroup, Button } from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const PasswordModalContent = styled.div`
  padding: 12px 20px;
`;

const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  margin: 8px 0 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;

  button:first-child {
    margin-right: 8px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: -2px;
`;

const LockWrapper = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${palette[PC.BackgroundPrimary]};
  `,
);

const LockSvg = styled.svg.attrs(() => ({
  width: 20,
  height: 26,
  viewBox: '0 0 20 26',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
}))``;

function LockIcon() {
  return (
    <LockWrapper>
      <LockSvg>
        {/* eslint-disable-next-line max-len */}
        <path d="M18.7292 9.875H17.1667V7.79167C17.1667 3.78125 13.8854 0.5 9.875 0.5C5.86458 0.5 2.58333 3.78125 2.58333 7.79167V9.875H1.02083C0.708333 9.875 0.5 10.0833 0.5 10.3958V23.4167C0.5 24.5625 1.4375 25.5 2.58333 25.5H17.1667C18.3125 25.5 19.25 24.5625 19.25 23.4167V10.3958C19.25 10.0833 19.0417 9.875 18.7292 9.875ZM11.4375 20.7604C11.4375 20.9167 11.3854 21.0729 11.3333 21.1771C11.2292 21.2812 11.0729 21.3333 10.9167 21.3333H8.83333C8.67708 21.3333 8.52083 21.2812 8.46875 21.1771C8.41667 21.0729 8.3125 20.9167 8.3125 20.7604L8.625 17.7917C8.10417 17.4271 7.79167 16.8021 7.79167 16.125C7.79167 14.9792 8.72917 14.0417 9.875 14.0417C11.0208 14.0417 11.9583 14.9792 11.9583 16.125C11.9583 16.8021 11.6458 17.4271 11.125 17.7917L11.4375 20.7604ZM14.0417 9.875H5.70833V7.79167C5.70833 5.5 7.58333 3.625 9.875 3.625C12.1667 3.625 14.0417 5.5 14.0417 7.79167V9.875Z" fill="#6879EB" />
      </LockSvg>
    </LockWrapper>
  );
}

const Styled = {
  PasswordModalContent,
  StyledInputGroup,
  LockWrapper,
  LockIcon,
  Title,
  ButtonWrapper,
  CloseButton,
};

export default Styled;
