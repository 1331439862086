import { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import {
  Search, Button,
} from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import { FontVariant as FV } from '@scaleflex/ui/utils/types/typography/font-variant';
import { objectValues } from '@scaleflex/ui/utils/functions';
import { useAuth, useCompany } from '@shared-admin-kit/core';
import { useTranslation, T } from '@shared-admin-kit/translation';
import PlusIcon from '@scaleflex/icons/plus';
import { useDebounce } from '../../hooks';
import { escapeRegExp } from '../../utils';
import Typography from '../typography';
import Company from './components/company';
import Styled from './tenancy-selector.styles';
import CanCrud from '../can-crud';
import ProjectModal from '../../routes/grids/components/project-modal';
import Box from '../box';


function TenancySelector(props) {
  const { t } = useTranslation();
  const { companies: sessionCompanies } = useAuth();
  const {
    activeCompanyUuid, activeProjectUuid, selectCompany, selectProject, activeProject,
  } = useCompany();
  const [projectModalState, setProjectModalState] = useState({ open: false, isNew: false });

  const companies = useMemo(() => (sessionCompanies)
    .map((company) => {
      const itemsArray = Object.keys(company?.projects?.items || {})
        .map((uuid) => ({ uuid, name: company.projects.items[uuid] }));
      // eslint-disable-next-line no-mixed-operators
      itemsArray.sort((a, b) => a.name > b.name && 1 || -1);

      return {
        ...(company || {}),
        projects: {
          ...(company?.projects || {}),
          itemsArray,
        },
      };
    }), [sessionCompanies]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const [matchedResultsCount, setMatchedResultsCount] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const searchMode = debouncedSearchTerm.length >= 2;

  useEffect(() => {
    setSearchTerm('');
  }, [props.open]);

  useEffect(() => {
    const searchTermRegexp = new RegExp(escapeRegExp(debouncedSearchTerm), 'i');
    // Check matches with company projects names
    const checkProjectsIncludes = (company) => objectValues(company?.projects?.items || {})
      .some((projectName) => searchTermRegexp.test(projectName));

    let newMatchedCount = 0;
    let newFilteredCompanies = searchMode
      ? (companies || [])
        .filter((company) => {
          const includesInCompanyName = (new RegExp(escapeRegExp(debouncedSearchTerm), 'i')).test(company?.name);

          if (includesInCompanyName) {
            newMatchedCount += 1;
          }

          return includesInCompanyName || checkProjectsIncludes(company);
        })
        .map((company) => (
          checkProjectsIncludes(company)
            ? ({
              ...company,
              projects: {
                ...(company.projects || {}),
                itemsArray: company?.projects?.itemsArray
                  .filter(({ name }) => {
                    if (!searchTermRegexp.test(name)) { return false; }
                    newMatchedCount += 1;
                    return true;
                  }),
              },
            })
            : company
        ))
      : [...companies];

    setMatchedResultsCount(searchMode ? newMatchedCount : 0);

    // Active company on top
    if (activeCompanyUuid) {
      const cIndex = newFilteredCompanies.findIndex((company) => company.uuid === activeCompanyUuid);

      if (cIndex > -1) {
        const activeCompnayItem = newFilteredCompanies.splice(cIndex, 1)[0];

        newFilteredCompanies = [
          activeCompnayItem,
          ...newFilteredCompanies,
        ];
      }
    }

    // Active project on top
    if (activeProjectUuid) {
      newFilteredCompanies = newFilteredCompanies.map((company) => {
        let newFilteredProjectItemsArray = [...(company?.projects?.itemsArray || [])];
        const pIndex = newFilteredProjectItemsArray
          .findIndex((projectItem) => projectItem.uuid === activeProjectUuid);
        if (pIndex > -1) {
          const activeProjectItem = newFilteredProjectItemsArray.splice(pIndex, 1)[0];

          newFilteredProjectItemsArray = [
            activeProjectItem,
            ...newFilteredProjectItemsArray,
          ];
        }

        return {
          ...company,
          projects: {
            ...(company.projects || {}),
            itemsArray: newFilteredProjectItemsArray,
          },
        };
      });
    }

    setFilteredCompanies(newFilteredCompanies);
  }, [companies, debouncedSearchTerm, searchMode, activeCompanyUuid, activeProjectUuid]);

  const activateProject = (projectUuid, companyUuid) => {
    if (!(activeProjectUuid && activeProjectUuid === projectUuid)) {
      if (companyUuid !== activeCompanyUuid) { // Company not active
        // HACK to activate correct project just after company activated. TODO - fix this part
        localStorage.setItem('project_uuid', projectUuid);
        sessionStorage.setItem('project_uuid', projectUuid);
        selectCompany(companyUuid);
        // .then(() => selectProject(projectUuid));
      } else {
        selectProject(projectUuid);
      }
      props.onClose();
    }

    if (activeProjectUuid === projectUuid) {
      // eslint-disable-next-line react/prop-types
      props.onClose();
      setSearchTerm('');
    }
  };
  const onCloseTenancySelector = () => {
    props.onClose();
  };
  const onEditProject = () => {
    setProjectModalState({ open: true, isNew: false });
    props.onClose();
  };

  return (
    <>
      <Styled.Menu
        {...props}
        popperOptions={
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [75, 0],
            },
          },
        ],
      }
    }
      >
        <Styled.TenancySelector
          tabIndex="0"
          onKeyDown={(event) => {
            if (event.code === 'Enter' && searchTerm && filteredCompanies) {
              const firstCompanyUuid = filteredCompanies?.[0]?.uuid;
              const firstProjectUuid = filteredCompanies?.[0]?.projects?.itemsArray?.[0]?.uuid;
              activateProject(firstProjectUuid, firstCompanyUuid);
            }

            if (event.code === 'Escape') {
              if (searchTerm) {
                setSearchTerm('');
              } else {
                props.onClose();
              }
            }
          }}
        >
          <Styled.SearchBlock>
            <Search
              value={searchTerm}
              onChange={({ target: { value } }) => setSearchTerm(value)}
              placeholder={t('SEARCH', 'Search')}
              fullWidth
              autoComplete="off"
              type="search"
              autoFocus
              style={{ height: 32 }}
            />

            <Typography
              fontVariant={FV.TextSmall}
              colorVariant={PC.TextSecondary}
              mt="10px"
              mb={0}
            >
              {searchMode
              // eslint-disable-next-line max-len
                ? `${t('YOUR_FILTER', 'Your filter')} "${searchTerm}" ${t('MATCHED', 'matched')} ${matchedResultsCount}
              ${t('TENANCY_RESULT_COUNT', `result${matchedResultsCount === 1 ? '' : 's'}`)}`
                : (
                  <Box>
                    <Button
                      color="link"
                      size="xs"
                      onClick={() => {
                        setProjectModalState({ open: true, isNew: true });
                        props.onClose();
                      }}
                      icon={(iconProps) => <PlusIcon {...iconProps} size={11} />}
                    >
                      <T i18nKey="ADD_PROJECT" defaultValue="Add Project" />
                    </Button>
                  </Box>
                )}
            </Typography>
          </Styled.SearchBlock>

          {filteredCompanies?.length > 0 && (
          <Styled.CompaniesBlock>
            {(filteredCompanies || []).map((company) => (
              <Company
                key={company.uuid}
                company={company}
                active={(activeCompanyUuid && activeCompanyUuid === company.uuid) || false}
                searchTerm={searchMode ? debouncedSearchTerm : ''}
                activeProjectUuid={activeProjectUuid}
                activateProject={activateProject}
                onCloseTenancySelector={onCloseTenancySelector}
                onEditProject={onEditProject}
              />
            ))}
          </Styled.CompaniesBlock>
          )}
        </Styled.TenancySelector>
      </Styled.Menu>
      <CanCrud>
        <ProjectModal
          {...projectModalState}
          onClose={() => setProjectModalState({ open: false, isNew: false })}
          project={projectModalState.isNew ? undefined : activeProject}
        />
      </CanCrud>
    </>
  );
}

TenancySelector.propTypes = {
  onClose: PT.func.isRequired,
  open: Boolean.open,
};

export default TenancySelector;
