const AVAILABLE_SYMBOLS = 'abcdefghijklmnopqrstuvwxyz';
const AVAILABLE_COLORS = [
  '#5B48A2',
  '#8FB021',
  '#D35C9E',
  '#8D44AD',
  '#16A086',
  '#297FB8',
  '#FFA800',
];

function getSymbolIndex(symbol) {
  return AVAILABLE_SYMBOLS.indexOf((symbol || '')?.toLowerCase());
}

function getColorBySymbol(symbol) {
  if (symbol) {
    const symbolIndex = getSymbolIndex(symbol);

    if (symbolIndex > -1) {
      const symbolsPerColor = Math.floor(AVAILABLE_SYMBOLS.length / AVAILABLE_COLORS.length);
      const colorIndex = Math.min(Math.floor(symbolIndex / symbolsPerColor), AVAILABLE_COLORS.length - 1);

      if (AVAILABLE_COLORS?.[colorIndex]) {
        return AVAILABLE_COLORS?.[colorIndex];
      }
    }
  }

  return AVAILABLE_COLORS?.[0] || null;
}

/**
 * @param {string} str
 * @param {boolean} [byFirstSymbol=true]
 * @returns
 */
function getColorByString(str, byFirstSymbol = true) {
  if (!str) return getColorBySymbol();
  if (byFirstSymbol) return getColorBySymbol(str?.[0]);

  // Get avarage string index
  const { count, totalIndexSum } = str.split('').reduce((accum, symbol) => {
    const symbolIndex = getSymbolIndex(symbol);
    return symbolIndex > -1 ? { count: accum.count + 1, totalIndexSum: accum.totalIndexSum + symbolIndex } : accum;
  }, { count: 0, totalIndexSum: 0 });
  const avarageIndex = Math.round(totalIndexSum / count);

  return getColorBySymbol(AVAILABLE_SYMBOLS[avarageIndex]);
}

export {
  getColorByString,
};
