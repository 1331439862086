/* eslint-disable arrow-body-style */
import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@scaleflex/ui/theme';
import SnackbarProvider from '@scaleflex/ui/snackbar';
import SharedAdminKit, { CoreProvider } from '@shared-admin-kit/core';
import { TranslationModule } from '@shared-admin-kit/translation';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { client } from './services/api/client';
import i18nInstance from './services/i18n.client';
import { createStore } from './store';
import { GlobalNotificationsProvider } from './providers/global-notifications-provider';
import MainLoader from './components/main-loader';
import { AUTHENTICATED_ROUTES, NOT_AUTHENTICATED_ROUTES, ROUTE_PATHS } from './routes';
import theme from './theme';
import App from './app';
// import reportWebVitals from './reportWebVitals';
import './index.css';


const {
  REACT_APP_AUTH_PUBLIC_URL,
  REACT_APP_DEV_LOGIN_ENABLED,
  REACT_APP_I18N_IT_BASE_URL,
  REACT_APP_I18N_GRID_UUID,
  REACT_APP_DEBUG_LANGUAGE,
} = process.env;

const history = createBrowserHistory();

const core = SharedAdminKit({
  client,
  history,
  authRoutes: Object.keys(AUTHENTICATED_ROUTES).map((routeKey) => AUTHENTICATED_ROUTES[routeKey]),
  publicRoutes: Object.keys(NOT_AUTHENTICATED_ROUTES).map((routeKey) => NOT_AUTHENTICATED_ROUTES[routeKey]),
  devLogin: { enabled: REACT_APP_DEV_LOGIN_ENABLED === 'true' },
  loginUrl: REACT_APP_AUTH_PUBLIC_URL,
  sessionChecker: { enabled: false, redirectSessionExpired: ROUTE_PATHS.SESSION_EXPIRED },
})
  .use(TranslationModule, {
    i18nInstance,
    i18nBaseUrl: REACT_APP_I18N_IT_BASE_URL,
    i18nGridUuid: REACT_APP_I18N_GRID_UUID,
    debug: REACT_APP_DEBUG_LANGUAGE === 'true',
  });

const store = createStore(core.getAllReducers(), history);

const handleForbiddenPage = () => {
  if (['/', AUTHENTICATED_ROUTES.GRIDS].includes(window.location.pathname)) {
    window.location.href = process.env.REACT_APP_AUTH_PUBLIC_URL;
  }

  return ''; // '403 Forbidden';
};

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18nInstance}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <CoreProvider core={core} loader={<MainLoader />} onForbiddenPage={handleForbiddenPage}>
                <GlobalNotificationsProvider>
                  <App />
                </GlobalNotificationsProvider>
              </CoreProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
