import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCompany } from '@shared-admin-kit/core';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import { T, useTranslation } from '@shared-admin-kit/translation';
import {
  InputGroup, Button, TagField,
} from '@scaleflex/ui/core';
import PT from 'prop-types';
import { selectAvailableLanguages } from '../../../../selectors/available-languages.selector';
import StyledModal from '../../../../components/styled-modal';
import StyledModalTitle from '../../../../components/styled-modal-title';
import StyledModalContent from '../../../../components/styled-modal-content';
import StyledModalActions from '../../../../components/styled-modal-actions';
import LanguageAutocomplete from '../../../../components/language-autocomplete';


function ProjectModal(props) {
  const { showMessage } = useSnackbar();
  const {
    open, onClose, project, isNew,
  } = props;
  const {
    refreshCurrentCompany, createProject, selectProject, editProject,
  } = useCompany();
  const availableLanguages = useSelector(selectAvailableLanguages);
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const projectLangsValue = useMemo( // Convert language key from DB to object (for better UI, to show labels instead of keys)
    () => (project?.langs || []).map((key) => {
      const languageDetails = (availableLanguages || []).find((lang) => lang.key === key);

      return {
        key,
        label: languageDetails ? languageDetails.label : key,
      };
    }),
    [project?.langs, availableLanguages],
  );
  const [projectState, setProjectState] = useState({
    name: project?.name || '',
    sourceLang: project?.data?.sourceLang || 'en',
    projectLangs: projectLangsValue,
  });
  const suggestedTags = useMemo(
    () => availableLanguages.map(({ key, label }) => ({ key, label })),
    [availableLanguages],
  );

  useEffect(() => { // Keep projectLangs state updated when source project.projectLangs updated (for example after refresh project request)
    setProjectState((_state) => ({
      ..._state,
      name: project?.name || '',
      sourceLang: project?.data?.sourceLang || 'en',
      projectLangs: projectLangsValue,
    }));
  }, [projectLangsValue, project]);

  const saveProject = async () => {
    setNameErrorMessage('');

    if (!projectState.name) {
      setNameErrorMessage(t('FIELD_IS_REQUIRED', 'Field is required!'));
      return;
    }

    setIsSaving(true);
    try {
      const projectData = {
        name: projectState.name,
        data: {
          source_lang: projectState.sourceLang,
          project_langs: projectState.projectLangs.map((item) => item.key), // Convert back object -> language key (to store good values in DB)
        },
      };
      let data;
      if (project?.uuid) {
        const response = await editProject(project?.uuid, projectData);
        showMessage('Update project successfully');
        data = response;
        refreshCurrentCompany();
      } else {
        const response = await createProject(projectData);
        showMessage('Create new project successfully');
        data = response;
      }
      selectProject(project?.uuid || data.project_uuid);
      onClose();
    } catch (error) {
      showMessage(error?.response?.data?.msg, { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <StyledModal
      fullWidth
      maxWidth="sm"
      style={{ height: 'auto', maxWidth: '500px' }}
      open={open}
      onClose={onClose}
    >
      <StyledModalTitle
        primary={isNew
          ? t('CREATE_PROJECT', 'Create project')
          : `${t('EDIT_PROJECT', 'Edit project')}: ${project?.name}`}
        onClose={onClose}
      />
      <StyledModalContent style={{ padding: 20 }}>
        <InputGroup
          error={Boolean(nameErrorMessage)}
          fullWidth
          label={t('NAME', 'Name')}
          placeholder={t('ENTER_NAME', 'Enter name')}
          value={projectState?.name || ''}
          onChange={({ target: { value } }) => {
            setProjectState((_state) => ({ ..._state, name: value }));
            setNameErrorMessage('');
          }}
          hint={nameErrorMessage}
        />
        <LanguageAutocomplete
          value={projectState?.sourceLang}
          onSelect={(newValue) => setProjectState((_state) => ({ ..._state, sourceLang: newValue }))}
        />
        <TagField
          fullWidth
          getTagValue={(item) => item?.key}
          getTagLabel={(item) => item?.label}
          label={t('TARGET_LANGUAGES', 'Target languages')}
          onAdd={(value) => {
            setProjectState((_state) => ({ ..._state, projectLangs: _state.projectLangs.concat(value) }));
          }}
          onRemove={(index) => {
            const newTags = [...projectState.projectLangs];
            newTags.splice(index, 1);
            setProjectState((_state) => ({ ..._state, projectLangs: newTags }));
          }}
          placeholder={t('ADD_LANGUAGE', 'Add Language')}
          tags={projectState?.projectLangs}
          suggestedTags={suggestedTags}
        />

      </StyledModalContent>

      <StyledModalActions
        align="center"
      >
        <Button
          color="link"
          onClick={onClose}
          style={{ margin: 0 }}
        >
          <T>Cancel</T>
        </Button>

        <Button
          color="primary"
          loading={isSaving}
          onClick={saveProject}
        >
          <T>{isNew ? 'Create' : 'Save'}</T>
        </Button>
      </StyledModalActions>
    </StyledModal>
  );
}
ProjectModal.defaultProps = {
  open: false,
  onClose: () => {},
  project: null,
};
ProjectModal.propTypes = {
  open: PT.bool,
  onClose: PT.func,
  isNew: PT.bool,
  project: PT.object,
};
export default ProjectModal;
