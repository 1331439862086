import { Tab, TabPanel } from '@scaleflex/ui/core';
import { T } from '@shared-admin-kit/translation';
import Box from '../components/box';


const getTabsWithPanels = (tabsArray, activateTabIndex, extraProps = {}) => {
  const tabsNames = [];
  const tabsPanels = [];

  tabsArray.forEach((
    {
      name, nameEnd, Component, Icon, TabPanelProps, TabPanelComponentProps, ...rest
    },
    index,
  ) => {
    tabsNames.push(
      <Tab
        {...rest}
        key={`${name}-tab`}
        value={index}
        label={(
          <Box display="flex" alignItems="center">
            <T>{name}</T>
            {nameEnd && (
              <Box display="flex" ml={1}>
                {typeof nameEnd === 'function' ? nameEnd(extraProps) : nameEnd}
              </Box>
            )}
          </Box>
        )}
      />,
    );

    tabsPanels.push(
      <TabPanel
        {...TabPanelProps}
        key={`${name}-tab-panel`}
        value={activateTabIndex}
        index={index}
      >
        <Component {...TabPanelComponentProps} />
      </TabPanel>,
    );
  });

  return [tabsNames, tabsPanels];
};

export default getTabsWithPanels;
