import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@shared-admin-kit/translation';
import { InputGroup, MenuItem, Menu } from '@scaleflex/ui/core';
import PT from 'prop-types';
import { selectAvailableLanguages } from '../../selectors/available-languages.selector';
import { createSearchPattern } from '../../utils/search';
import { useDebounce } from '../../hooks';
import Styled from './styled-components';


function LanguageAutocomplete({
  value, onSelect, label, placeholder,
}) {
  const availableLanguages = useSelector(selectAvailableLanguages);
  const { t } = useTranslation();
  const [sourceLanguageAnchorEl, setSourceLanguageAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [sourceLanguageFocused, setSourceLanguageFocused] = useState(false);
  const debouncedSearchKey = useDebounce(searchKey, 300);
  const filteredAvailableLanguages = useMemo(() => {
    const regexp = createSearchPattern(debouncedSearchKey);
    return debouncedSearchKey ? (
      availableLanguages.filter((lang) => (regexp.test(lang.label)))) : availableLanguages;
  }, [debouncedSearchKey, availableLanguages]);

  const sourceLangLabel = useMemo(() => (
    value
      ? (availableLanguages || []).find((lang) => lang.key === value)?.label || ''
      : ''
  ), [value, availableLanguages]);

  return (
    <>
      <InputGroup
        fullWidth
        style={{ marginBottom: 16 }}
        label={label || t('LANGUAGE_AUTOCOMPLETE.LABEL', 'Source language')}
        placeholder={placeholder || t('LANGUAGE_AUTOCOMPLETE.PLACEHOLDER', 'Enter language')}
        onFocus={(event) => {
          setSourceLanguageAnchorEl(event.currentTarget.parentElement);
          if (sourceLangLabel) setSearchKey(sourceLangLabel);
          setSourceLanguageFocused(true);
        }}
        onBlur={() => setSourceLanguageFocused(false)}
        value={(sourceLanguageFocused ? searchKey : sourceLangLabel) || ''}
        onChange={({ target: { value: newValue } }) => setSearchKey(newValue)}
      />
      <Menu
        anchorEl={sourceLanguageAnchorEl}
        onClose={() => setSourceLanguageAnchorEl(null)}
        open={Boolean(sourceLanguageAnchorEl)}
        position="bottom"
        style={{ maxHeight: 156, overflowY: 'auto' }}
      >
        {
          filteredAvailableLanguages.length > 0 ? (
            filteredAvailableLanguages.map((lang) => (
              <MenuItem
                key={lang.key}
                value={lang.key}
                onClick={() => {
                  onSelect(lang.key);
                  setSourceLanguageAnchorEl(null);
                }}
              >
                <Styled.FlagImage
                  src={lang.flag_uri}
                  alt={lang.flag_uri}
                />
                <span>{lang.label}</span>
              </MenuItem>
            ))
          ) : (
            <MenuItem>{t('LANGUAGES_DOES_NOT_EXIST', 'Language does not exist')}</MenuItem>
          )
        }
      </Menu>
    </>
  );
}

LanguageAutocomplete.propTypes = {
  value: PT.string,
  onSelect: PT.func.isRequired,
  label: PT.node,
  placeholder: PT.node,
};

export default LanguageAutocomplete;
