import GeneralTab from './components/general-tab';
// import SecurityTab from './components/security-tab';


const generateTab = (options) => ({
  size: 'lg',
  TabPanelProps: {
    style: {
      overflow: 'hidden',
      flexGrow: 1,
      marginTop: 0,
    },
  },
  ...options,
});

const TAB_NAME = {
  General: 'General',
  // Security: 'Security',
};

const TABS = [
  generateTab({
    name: TAB_NAME.General,
    icon: undefined,
    Component: (props) => <GeneralTab {...props} />,
  }),

  // generateTab({
  //   name: TAB_NAME.Security,
  //   icon: undefined,
  //   Component: (props) => <SecurityTab {...props} />,
  // }),
];

export { TAB_NAME, TABS };
