import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import availableLanguages from './available-languages.reducer';


export default function createReducer(history, sharedReducers = {}) {
  return combineReducers({
    ...(sharedReducers || {}),
    router: connectRouter(history),
    availableLanguages,
  });
}
