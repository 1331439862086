import { lazy } from 'react';
import {
  Switch, Route as RouterRoute, Redirect,
} from 'react-router-dom';
import Layout from '../components/layout';
import Route from './route';

// const {
// REACT_APP_AUTH_PUBLIC_URL,
// } = process.env;

const AUTHENTICATED_ROUTES = {
  GRIDS: '/grids',
  USERS: '/users',
  GRID: '/g/:gridUuid',
};

const NOT_AUTHENTICATED_ROUTES = {
  // CONTACT: '/contact',
  INVITE_REGISTER: '/invite-register', // invite-register?email=...&related_text=
  RETRIEVE_PASSWORD: '/retrieve-password/:token',
  // AUTOCONNECT: '/autoconnect/:autoconnectUuid',
};

const ROUTE_PATHS = {
  ...NOT_AUTHENTICATED_ROUTES,
  ...AUTHENTICATED_ROUTES,
};

const Grids = lazy(() => import('./grids'));
const Users = lazy(() => import('./users'));
const InviteRegister = lazy(() => import('./invite-register'));
const RetrievePassword = lazy(() => import('./retrieve-password'));
const Grid = lazy(() => import('./grid'));
// const Autoconnect = lazy(() => import('./autoconnect'));

function Routes(props = {}) {
  return (
    <Switch>
      {/* Routes without layout */}
      {/* <Route exact path={ROUTES.LOGOUT} component={Logout} extraProps={{ ...props, redirect: REACT_APP_AUTH_PUBLIC_URL }} /> */}
      <Route exact path={NOT_AUTHENTICATED_ROUTES.INVITE_REGISTER} component={InviteRegister} />
      <Route exact path={NOT_AUTHENTICATED_ROUTES.RETRIEVE_PASSWORD} component={RetrievePassword} />
      {/* <Route exact path={NOT_AUTHENTICATED_ROUTES.AUTOCONNECT} component={Autoconnect} /> */}

      <RouterRoute>
        <Layout>
          <Switch>
            <Route exact path={ROUTE_PATHS.GRIDS} component={Grids} extraProps={props} />
            <Route exact path={ROUTE_PATHS.USERS} component={Users} extraProps={props} />
            <Route exact path={ROUTE_PATHS.GRID} component={Grid} extraProps={props} />

            {/* <Route component={NotFound} /> */}
            <Redirect to={ROUTE_PATHS.GRIDS} />
          </Switch>
        </Layout>
      </RouterRoute>
    </Switch>
  );
}

export default Routes;

export {
  AUTHENTICATED_ROUTES,
  NOT_AUTHENTICATED_ROUTES,
  ROUTE_PATHS,
};
