import { useState, useEffect } from 'react';
import { useTabs } from './use-tabs';


function useModalTabs(TABS, { onClose, title: defaultTitle = 'Modal title', open } = {}) {
  const [isTabsPanelVisible, setIsTabsPanelVisible] = useState(true);
  const [title, setTitle] = useState(defaultTitle);
  const {
    tabs, tabsPanels, activeTabIndex, setActiveTabIndex,
  } = useTabs({
    tabs: TABS,
    TabPanelComponentProps: {
      open, // Can be used in useEffect to set default values
      onClose: typeof onClose === 'function' ? onClose : () => {},
      changeTabsPanelVisibility: setIsTabsPanelVisible,
      changeModalTitle: (newTitle = defaultTitle) => setTitle(newTitle),
      activateTabByName: (tabName) => {
        const index = TABS.findIndex(({ name }) => name === tabName);
        if (index > -1) {
          setActiveTabIndex(index);
        }
      },
    },
  });

  useEffect(() => {
    setActiveTabIndex(0);
    setIsTabsPanelVisible(true);
    setTitle(defaultTitle);
  }, [open]);

  return {
    activeTabIndex,
    setActiveTabIndex,
    tabs,
    tabsPanels,
    isTabsPanelVisible,
    setIsTabsPanelVisible,
    title,
    setTitle,
  };
}

export {
  useModalTabs,
};
