import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import StyledDivider from '../../../divider/divider.styles';
import logo from '../../../../assets/logo.svg';


const TopNav = styled.div(
  ({ theme: { palette } }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
  padding: 0 60px;
  background: ${palette[PC.BackgroundPrimary]};
  `,
);

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
`;

const CompanyName = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-right: 36px;

    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 22px;
    color: ${theme.palette[PC.TextPrimary]};
    white-space: nowrap;
    cursor: pointer;
  `,
);

const Navigation = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto;
`;

const NavigationItem = styled.div(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['text-normal']}
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37414B;
    padding: 21px 24px;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      font-weight: 500;
    }

    &.active {
      font-weight: 500;
      color: #37414B;
      &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        background: ${palette[PC.AccentPrimary]};
      }
    }
  `,
);

const NavigationItemDropdown = styled(NavigationItem)`
  padding: 0;
  > * {
    padding: 21px 24px;
  }
`;

const RightBlock = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const VerticalDivider = styled(StyledDivider.Divider)`
  width: 1px;
  height: 36px;
  margin: 0 0 0 16px;
`;

const Icons = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    color:  ${palette[PC.IconsPrimary]};

    > * {
      margin: auto 16px;
    }
  `,
);
const Logo = styled.img.attrs({ src: logo })`
  margin-right: 50px;
  cursor: pointer;
`;


const Styled = {
  TopNav,
  LeftBlock,
  CompanyName,
  Logo,
  Navigation,
  NavigationItem,
  NavigationItemDropdown,
  RightBlock,
  VerticalDivider,
  Icons,
};

export default Styled;
