import styled from 'styled-components';
import SfxSpinnerIcon from '@scaleflex/icons/spinner';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SpinnerIcon = styled(({ color, ...rest }) => <SfxSpinnerIcon {...rest} />)(
  ({ theme: { palette }, color = PC.IconsPrimary }) => `
    animation: spinner 1.2s linear infinite !important;
    color: ${color ? (palette[color] || color) : palette[PC.IconsPrimary]};
  `,
);

const Styled = {
  Container,
  SpinnerIcon,
};

export default Styled;
