import styled, { css } from 'styled-components';
import { Tabs as SfxTabs } from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const StyledModalTabs = styled(SfxTabs)(
  ({ theme: { palette } }) => css`
    padding: 0 20px;
    margin-bottom: 20px;
    background: ${palette[PC.BackgroundPrimary]};
    border-bottom: 1px solid ${palette[PC.BordersSecondary]};
    flex-shrink: 0;
    color: ${palette[PC.TextPrimary]};

    .SfxTab-root:not(.active, :hover) svg {
      color: ${palette[PC.IconsPrimary]};
    }
  `,
);

export default StyledModalTabs;
