import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import PT from 'prop-types';
import { Modal as SfxModal, Button } from '@scaleflex/ui/core';
import { useTheme } from '@scaleflex/ui/theme/hooks';
import CancelIcon from '@scaleflex/icons/cross';
import LockIcon from '@scaleflex/icons/lock';
import { useSnackbar } from '@scaleflex/ui/snackbar';
import { useTranslation, T } from '@shared-admin-kit/translation';

import Styled from './password-settings-modal.styled';
import { changePasswordRequest } from '../../services/api/password.service';
import { handleFormError } from '../../utils/react-hook-forms';
import FormInputGroup from '../form-input-group';
import { PC } from '../typography';


function PasswordSettingsModal({ open, onClose }) {
  const defaultValues = useMemo(() => ({ old_password: '', new_password: '', new_password2: '' }), [open]);
  const { showMessage } = useSnackbar();
  const theme = useTheme();
  const {
    register, setValue, watch, handleSubmit, errors, setError, reset, trigger, formState,
  } = useForm({ defaultValues });
  const { t } = useTranslation();
  const form = watch();
  const { dirtyFields } = formState;
  const fieldProps = {
    form, errors, setValue, trigger, dirtyFields, inputProps: { type: 'password' },
  };
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    register('old_password', { required: t('FIELD_IS_REQUIRED', 'Field is required!') });
    register('new_password', { required: t('FIELD_IS_REQUIRED', 'Field is required!') });
  }, [register]);

  useEffect(() => {
    register('new_password2', { // Confirm password
      required: t('FIELD_IS_REQUIRED', 'Field is required!'),
      validate: (confirmPassValue) => (
        form?.new_password === confirmPassValue
          ? true
          : t('CONFIRM_PASSWORD_IS_NOT_CORRECT', 'Confirm password is not correct!')
      ),
    });
  }, [register, form?.new_password]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);


  const handleFormSubmit = (formData) => {
    setIsSaving(true);

    return changePasswordRequest(formData)
      .then(() => {
        showMessage(t('PASSWORD_CHANGED', 'Password changed'));
        onClose();
      })
      .catch((error) => {
        const message = error?.response?.data?.msg;

        showMessage(message || t('PASSWORD_CHANGE_ERROR', 'Password change error! Contact us!'), { variant: 'error' });

        if (message && (new RegExp('current password seems incorrect', 'i').test(message))) {
          setError('old_password', { type: 'manual', message });
        } else if (message && (new RegExp('password', 'i').test(message))) {
          setError('new_password', { type: 'manual', message });
        }
      })
      .finally(() => setIsSaving(false));
  };

  if (!open) {
    return null;
  }

  return (
    <SfxModal
      fullWidth
      open={open}
      onClose={onClose}
    >
      <Styled.CloseButton
        color="link"
        size="xs"
        icon={() => <CancelIcon />}
        onClick={onClose}
      />

      <Styled.PasswordModalContent>
        <Styled.LockWrapper>
          <LockIcon size={26} color={theme.palette[PC.AccentPrimary]} />
        </Styled.LockWrapper>
        <Styled.Title>
          <T i18nKey="CHANGE_PASSOWRD" defaultValue="Change Password" />
        </Styled.Title>

        <FormInputGroup
          {...fieldProps}
          name="old_password"
          label={t('CURRENT_PASSWORD', 'Current Password')}
          // autoFocus
        />
        <FormInputGroup
          {...fieldProps}
          name="new_password"
          label={t('NEW_PASSWORD', 'New Password')}
        />
        <FormInputGroup
          {...fieldProps}
          name="new_password2"
          label={t('CONFIRM_NEW_PASSWORD', 'Confirm New Password')}
        />

        <Styled.ButtonWrapper>
          <Button onClick={onClose} color="link" size="md">
            <T i18nKey="CANCEL" defaultValue="Cancel" />
          </Button>

          <Button
            onClick={handleSubmit(handleFormSubmit, handleFormError.bind(null, showMessage))}
            color="primary"
            size="md"
            loading={isSaving}
          >
            <T i18nKey="CHANGE_PASSOWRD" defaultValue="Change Password" />
          </Button>
        </Styled.ButtonWrapper>

      </Styled.PasswordModalContent>
    </SfxModal>
  );
}

PasswordSettingsModal.defaultProps = {
  open: false,
  onClose: () => {},
};

PasswordSettingsModal.propTypes = {
  open: PT.bool,
  onClose: PT.func,
};

export default PasswordSettingsModal;
