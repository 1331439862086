import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useCompany } from '@shared-admin-kit/core';
import { getTKey } from '@shared-admin-kit/translation';
import {
  getGridTranslationsRequest, addGridItemRequest, deleteGridKeysRequest, updateGridItemRequest,
} from '../services/api/grid.service';
import { escapeRegExp } from '../utils';


function useGrid({ gridUuid, filterKeyWord, showMissingData = false }) {
  const [state, setState] = useState(null);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [isNoCompanyAccess, setNoCompanyAccess] = useState(false);
  const [isNoProjectAccess, setNoProjectAccess] = useState(false);
  const [canGridView, setCanGridView] = useState(false);
  const [canGridEdit, setCanGridEdit] = useState(false);
  const [isKeyExists, setKeyExists] = useState(false);
  const {
    activeCompanyUuid, activeProject, isCompanyLoading, isProjectLoading, selectCompany, selectProject,
  } = useCompany();
  const isLoading = isCompanyLoading || isProjectLoading || isGridLoading;
  const companyUuid = state?.response?.company?.uuid;
  const projectUuid = state?.response?.project?.uuid;

  const filteredData = useMemo(() => {
    let _keyExists = false;
    const regexp = new RegExp(escapeRegExp(filterKeyWord), 'i');
    const filterKeyWordTransformed = getTKey(filterKeyWord || '', { maxLength: 100 });

    const remainData = (
      filterKeyWord
        ? (state?.data || []).filter((item) => {
          if (filterKeyWordTransformed === item?.translation_key) {
            _keyExists = true;
          }

          return (
            regexp.test(item?.translation_key) // Check in key
            || (state?.showLanguages || []).some((langKey) => regexp.test(item.translations[langKey])) // Check in translations
          );
        })
        : state?.data || []);

    setKeyExists(_keyExists);

    if (!showMissingData) {
      return remainData;
    }

    return remainData.filter((item) => (state?.showLanguages || []).some((lang) => !item.translations[lang]));
  },
  [showMissingData, state?.showLanguages, state?.data, filterKeyWord]);

  const refreshGrid = useCallback((showLoader = true) => {
    if (showLoader) setIsGridLoading(true);
    getGridTranslationsRequest(gridUuid)
      .then((response) => {
        const data = response?.data || {};
        const langs = data?.project?.langs || [];

        setNoCompanyAccess(!data?.company?.uuid);
        setNoProjectAccess(!data?.project?.uuid);
        setCanGridView(Boolean(data?.access?.can_view_grid));
        setCanGridEdit(Boolean(data?.access?.can_edit_grid));
        setState({
          response: data,
          data: data.translations,
          info: data.grid,
          languages: langs,
          mainLanguage: langs?.[0],
          showLanguages: langs.slice(0, 2),
        });
      })
      .finally(() => { setIsGridLoading(false); });
  }, [gridUuid]);

  useEffect(() => {
    refreshGrid();
  }, [refreshGrid]);

  useEffect(() => {
    if (!isLoading && !isNoCompanyAccess && !isNoProjectAccess && companyUuid && projectUuid) {
      const companyAlreadySelected = activeCompanyUuid && activeCompanyUuid === companyUuid;
      const projectAlreadySelected = activeProject?.uuid && activeProject?.uuid === projectUuid;

      if (!companyAlreadySelected) {
        localStorage.setItem('project_uuid', projectUuid);
        sessionStorage.setItem('project_uuid', projectUuid);
        selectCompany(companyUuid);
      } else if (!projectAlreadySelected) {
        selectProject(projectUuid);
      }
    }
  }, [activeCompanyUuid, activeProject, isLoading, isNoCompanyAccess, companyUuid, projectUuid]);

  const createGridKey = async (key) => {
    await addGridItemRequest(gridUuid, key);
    setState({
      ...state,
      data: [
        {
          translation_key: key,
          translations: {},
        },
        ...state.data,
      ],
    });
  };

  const createLocalGridKey = () => {
    setState({
      ...state,
      data: [
        {
          translation_key: '',
          translations: {},
        },
        ...state.data,
      ],
    });
  };

  const updateGridData = async (key, language, value) => {
    // eslint-disable-next-line camelcase
    const index = state.data.findIndex(({ translation_key }) => translation_key === key);
    if (index >= 0) {
      const newData = [...state.data];
      newData[index] = {
        ...newData[index],
        translations: {
          ...state.data[index].translations,
          [language]: value,
        },
      };
      setState({
        ...state,
        data: newData,
      });
      await updateGridItemRequest(gridUuid, key, {
        ...state.data[index].translations,
        [language]: value,
      });
    }
  };

  const updateGridKey = async (key, value) => {
    // eslint-disable-next-line camelcase
    const index = state.data.findIndex(({ translation_key }) => translation_key === key);
    if (index >= 0) {
      const newData = [...state.data];
      newData[index] = {
        ...newData[index],
        translation_key: value,
      };
      setState({
        ...state,
        data: newData,
      });
      await updateGridItemRequest(gridUuid, value, state.data[index].translations);
    }
  };
  const updateLocalGridKey = async (key) => {
    await addGridItemRequest(gridUuid, key);
    const newData = [...state.data];
    newData[0] = {
      ...newData[0],
      translation_key: key,
      translations: {},
    };
    setState({
      ...state,
      data: newData,
    });
  };

  const deleteGridKeys = async (keys) => {
    try {
      await deleteGridKeysRequest(gridUuid, keys);
    } finally {
      refreshGrid(false);
    }
  };

  const deleteLocalGridKeys = () => {
    setState({
      ...state,
      data: [],
    });
  };

  const addShowLanguage = (lang) => {
    setState((_state) => ({ ..._state, showLanguages: [...state.showLanguages, lang] }));
  };

  const removeShowLanguage = (lang) => {
    setState({
      ...state,
      showLanguages: state.showLanguages.filter((item) => item !== lang),
    });
  };

  return {
    grid: state,
    isNoCompanyAccess,
    isNoProjectAccess,
    canGridView,
    canGridEdit,
    isLoading,
    isKeyExists,
    createGridKey,
    updateGridData,
    updateGridKey,
    updateLocalGridKey,
    deleteGridKeys,
    addShowLanguage,
    removeShowLanguage,
    filteredData,
    createLocalGridKey,
    deleteLocalGridKeys,
    refreshGrid,
  };
}

export { useGrid };
