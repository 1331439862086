import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const Dropdown = styled.div(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['text-normal']}
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;

    :hover {
      color: ${palette[PC.LinkHover]} !important;
    }
  `,
);

const DropdownLabel = styled.div(
  () => css`
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  `,
);

const DropdownIcon = styled.div(
  () => css`
    display: inline-block;
    flex-shrink: 1;
    margin-left: 10px;
    margin-right: 15px;
  `,
);

const DropdownItemText = styled.div(
  () => css`
    margin-left: 8px;
  `,
);

const Styled = {
  Dropdown,
  DropdownLabel,
  DropdownIcon,
  DropdownItemText,
};

export default Styled;
