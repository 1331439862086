import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from '@scaleflex/i18n-it';


const { REACT_APP_SAVE_TRANSLATIONS } = process.env;
const saveTranlations = typeof REACT_APP_SAVE_TRANSLATIONS !== 'undefined'
  ? REACT_APP_SAVE_TRANSLATIONS === 'true' : true;
const newInstance = i18n.createInstance();

newInstance
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    load: 'languageOnly',
    keySeparator: false,
    nsSeparator: false,
    saveMissing: saveTranlations,
    react: {
      useSuspense: true,
    },
    backend: {
      uuid: process.env.REACT_APP_I18N_GRID_UUID,
      cache: true,
      langPath: `${process.env.REACT_APP_I18N_IT_BASE_URL}/api/export/grid/{{uuid}}/langs`,
      loadPath: `${process.env.REACT_APP_I18N_IT_BASE_URL}/api/export/grid/f2/{{uuid}}`,
      savePath: `${process.env.REACT_APP_BASE_URL}/import/request-translations?grid_uuid={{uuid}}`,
    },
  });

export default newInstance;
