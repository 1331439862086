import styled from 'styled-components';


const FieldsRow = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * {
    width: 100%;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Styled = {
  FieldsRow,
};

export default Styled;
