import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const ItemWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `,
);

const Dropdown = styled.div(
  ({ theme: { palette, typography: { font } } }) => css`
    ${font['text-normal']}
    display: flex;
    align-items: center;
    color: ${palette[PC.TextPrimary]};
    /* min-width: 100px; */
    /* max-width: 200px; */
  `,
);

const Styled = {
  Dropdown,
  ItemWrapper,
};

export default Styled;
