import styled from 'styled-components';
import { Modal as SfxModal } from '@scaleflex/ui/core';


const StyledModal = styled(SfxModal)`
  max-width: 1100px;
  height: 800px;
  overflow: hidden;

  .SfxModal-root {
    overflow: hidden;
    height: 100%;
  }

  &.locked {
    cursor: wait;

      .SfxModal-root{
        pointer-events: none;
      }
  }
`;

const Styled = {
  StyledModal,
};


export default Styled;
