import { PC } from './components/typography';


const defaultTheme = {
  palette: {
    [PC.AccentPrimary]: '#82BE18',
    [PC.AccentPrimaryHover]: '#73A914',
    [PC.AccentPrimaryActive]: '#73A914',
    [PC.BackgroundPrimary]: '#F8FAFB',
    [PC.BorderActiveBottom]: '#82BE18',
  },
};

export default defaultTheme;
