import { objectValues } from '@scaleflex/ui/utils/functions';
import { PropName, PropNameDetail, PropNameType } from './types';
import { simplePropNameStyle, spacingPropNameStyle, sizePropNameStyle } from './box.utils';


export const propNameMixin = objectValues(PropName)
  .reduce(
    (accumulator, currentPropName) => {
      const detail = PropNameDetail[currentPropName];

      if (detail) {
        const { type } = detail;
        const { stylePropName } = detail;

        if (type && stylePropName) {
          switch (type) {
            case PropNameType.Spacing:
              accumulator[currentPropName] = spacingPropNameStyle.bind(null, stylePropName);
              break;

            case PropNameType.Size:
              accumulator[currentPropName] = sizePropNameStyle.bind(null, stylePropName);
              break;

            case PropNameType.Simple:
            default:
              accumulator[currentPropName] = simplePropNameStyle.bind(null, stylePropName);
          }
        }
      }

      return accumulator;
    },
    {},
  );
