import { intrinsicComponent } from '@scaleflex/ui/utils/functions';
import Styled from './box.styles';
import { propNamePropTypes } from './types';


const Box = intrinsicComponent(({ ...rest }, ref) => (
  <Styled.Box {...rest} ref={ref} />
));

Box.propTypes = propNamePropTypes;

export default Box;
