import PT from 'prop-types';
import { useAuth } from '@shared-admin-kit/core';
import { ROLE } from '../../services/user.service';


function CanCrud({ children }) {
  const { isUserAuthentication, isUserLoading, role } = useAuth();

  if (isUserAuthentication || isUserLoading || ![ROLE.OWNER, ROLE.ADMIN].includes(role)) {
    return '';
  }

  return children;
}

CanCrud.defaultProps = {
  children: '',
};

CanCrud.propTypes = {
  children: PT.node,
};

export default CanCrud;
