import styled from 'styled-components';


const Container = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }

  .SfxTextarea-root {
    width: 100%;
  }

  .SfxLabel-root {
    margin-bottom: 4px;
  }

  .SfxFormHint-root {
    margin-top: 4px;
  }
`;

const Styled = {
  Container,
};

export default Styled;
