import { T } from '@shared-admin-kit/translation';


/**
 * Usage:
 *  handleSubmit(handleFormSubmit, handleFormError.bind(null, showMessage))
 */
function handleFormError(showMessage, errorDetails) {
  // eslint-disable-next-line no-console
  console.warn('Form error details:', errorDetails);

  showMessage(
    (
      <div>
        <div><T i18nKey="FORM_NOT_VALID" defaultValue="Form not valid!" /></div>
        {/* {Object.keys(errorDetails).length && (<div>{`Check fields: ${Object.keys(errorDetails).join(', ')}.`}</div>)} */}
      </div>
    ),
    { variant: 'error' },
  );
}

export {
  handleFormError,
};
