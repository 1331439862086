import { objectKeys } from '@scaleflex/ui/utils/functions';
import { styleSpacingValue, styleSizeValue } from '../../utils';

/**
 * @param {string | string[]} propName
 * @param {*} value
 * @returns {string}
 */
const simplePropNameStyle = (propName, value) => (Array.isArray(propName) ? propName : [propName])
  .map((pn) => `${pn}: ${value};`)
  .join(' ');

/**
 * @param {string | string[]} propName
 * @param {*} value
 * @returns {string}
 */
const spacingPropNameStyle = (propName, value) => {
  const formattedValue = styleSpacingValue(value);

  return (Array.isArray(propName) ? propName : [propName])
    .map((pn) => `${pn}: ${formattedValue};`)
    .join(' ');
};

/**
 * @param {string | string[]} propName
 * @param {*} value
 * @returns {string}
 */
const sizePropNameStyle = (propName, value) => {
  const formattedValue = styleSizeValue(value);

  return (Array.isArray(propName) ? propName : [propName])
    .map((pn) => `${pn}: ${formattedValue};`)
    .join(' ');
};


const filterObjectByPropName = (obj, ignorePropName) => objectKeys(obj)
  .reduce(
    (accumulator, currentPropName) => {
      let ignoreArr = false;

      if (Array.isArray(ignorePropName)) {
        ignoreArr = ignorePropName;
      } else if (ignorePropName && typeof ignorePropName === 'string') {
        ignoreArr = [ignorePropName];
      }

      if (!ignoreArr || ignoreArr.indexOf(currentPropName) === -1) {
        accumulator[currentPropName] = obj[currentPropName];
      }

      return accumulator;
    },
    {},
  );


export {
  simplePropNameStyle,
  sizePropNameStyle,
  spacingPropNameStyle,
  filterObjectByPropName,
};
