import { useState } from 'react';
import { dotPropWithArray as dotProp } from '../utils';


function useFormField({
  name = '',
  form = {},
  errors = {},
  setValue = () => {},
  trigger = () => {},
  dirtyFields = {},
  hint = '',
  disableBlur = false,
}) {
  const [isBlured, setIsBlurred] = useState(disableBlur);
  const isDirty = Boolean(dotProp.get(dirtyFields || {}, name));

  const onChange = (newValue) => {
    setValue(name, newValue, { shouldDirty: true });

    if (isBlured) {
      trigger(name);
    }
  };

  const onBlur = () => {
    if (isDirty) {
      trigger(name);
      setIsBlurred(true);
    }
  };

  return {
    value: dotProp.get(form || {}, name) || '',
    error: Boolean(dotProp.get(errors || {}, name)),
    hint: dotProp.get(errors || {}, name)?.message || hint || '',
    onChange,
    onBlur,
  };
}

export { useFormField };
