import PT from 'prop-types';
// import { objectValues } from '@scaleflex/ui/utils/functions';
import { Color } from '@scaleflex/ui/utils/types/palette/color';
import Styled from './spinner.styles';


function Spinner({
  container, color, size, ...rest
}) {
  const iconProps = {
    color,
    size,
  };

  if (container) {
    return (
      <Styled.Container {...rest}>
        <Styled.SpinnerIcon {...iconProps} />
      </Styled.Container>
    );
  }

  return (
    <Styled.SpinnerIcon {...rest} {...iconProps} />
  );
}

Spinner.defaultProps = {
  container: false,
  color: Color.IconsPrimary,
  size: undefined,
};

Spinner.propTypes = {
  container: PT.bool,
  // color: PT.oneOf(objectValues(Color)),
  color: PT.string,
  size: PT.number,
};

export default Spinner;
