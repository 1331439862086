/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';

/**
 * API Docs -- https://www.deepl.com/docs-api/translating-text/request/
 *
 * Examples:
 *
 * Request:
 * curl https://api.deepl.com/v2/translate \
 *  -d auth_key=20da2272-d939-a084-c0cf-6ea8051a889c:fx \
 *  -d "text=Hello, world!"  \
 *  -d "target_lang=DE"
 *
 * Response:
 * {
 *  "translations": [{
 *    "detected_source_language":"EN",
 *    "text":"Hallo, Welt!"
 *  }]
 * }
 */

const deeplClient = axios.create({
  baseURL: 'https://api.deepl.com/v2',
  params: {
    auth_key: process.env.REACT_APP_DEEPL_TOKEN,
  },
});

const DEEPL_AVAILABLE_SOURCE_LANGUAGES = [
  'BG',
  'CS',
  'DA',
  'DE',
  'EL',
  'EN',
  'ES',
  'ET',
  'FI',
  'FR',
  'HU',
  'ID',
  'IT',
  'JA',
  'LT',
  'LV',
  'NL',
  'PL',
  'PT',
  'RO',
  'RU',
  'SK',
  'SL',
  'SV',
  'TR',
  'ZH',
];

const DEEPL_AVAILABLE_TARGET_LANGUAGES = [
  'BG',
  'CS',
  'DA',
  'DE',
  'EL',
  'EN-GB',
  'EN-US',
  'EN',
  'ES',
  'ET',
  'FI',
  'FR',
  'HU',
  'ID',
  'IT',
  'JA',
  'LT',
  'LV',
  'NL',
  'PL',
  'PT-PT',
  'PT-BR',
  'PT',
  'RO',
  'RU',
  'SK',
  'SL',
  'SV',
  'TR',
  'ZH',
];

const MAX_TRANSLATIONS_LIMIT_PER_REQUEST = 50;

/**
 * Translate texts with AI, powered by DeepL Translator
 *
 * @param {Object} params
 * @param {string|string[]} params.text - (Required) Text to be translated. Only UTF8-encoded plain text is supported. The parameter may be specified multiple times and translations are returned in the same order as they are requested. Each of the parameter values may contain multiple sentences. Up to 50 texts can be sent for translation in one request.
 * @param {string} params.targetLang - (Required) The language into which the text should be translated.
 * @returns
 */
const getTranslationsRequest = ({
  targetLang: _targetLang = null, sourceLang: _sourceLang = null, text, ...restParams
} = {}) => {
  const targetLang = _targetLang ? _targetLang.toUpperCase() : null;
  const sourceLang = _sourceLang ? _sourceLang.toUpperCase() : null;

  if (!text || (Array.isArray(text) && text.length === 0)) {
    return Promise.reject(new Error('text field is required!'));
  }

  if (Array.isArray(text) && text.length > MAX_TRANSLATIONS_LIMIT_PER_REQUEST) {
    return Promise.reject(new Error(`Max translations limit per request is ${MAX_TRANSLATIONS_LIMIT_PER_REQUEST}`));
  }

  if (!targetLang) {
    return Promise.reject(new Error('target_lang field is required!'));
  }

  if (!DEEPL_AVAILABLE_TARGET_LANGUAGES.includes(targetLang)) {
    return Promise.reject(new Error(`target_lang "${targetLang}" is not supported (read DeepL API docs)!`));
  }

  if (sourceLang && !DEEPL_AVAILABLE_SOURCE_LANGUAGES.includes(sourceLang)) {
    return Promise.reject(new Error(`source_lang "${sourceLang}" is not supported (read DeepL API docs)!`));
  }

  const data = {
    text,
    source_lang: sourceLang, // (Optional)
    target_lang: targetLang,
    split_sentences: 0,
    preserve_formatting: 0,
    tag_handling: 'xml',
    ...(restParams || {}),
    // text: restParams?.text ? JSON.stringify(restParams?.text) : null,
    auth_key: process.env.REACT_APP_DEEPL_TOKEN,
  };

  let formBody = [];
  for (const property in data) {
    if (property !== 'text' && data[property]) {
      formBody.push(`${encodeURIComponent(property)}=${encodeURIComponent(data[property])}`);
    }
  }
  if (typeof text === 'string') {
    formBody.push(`text=${encodeURIComponent(text)}`);
  } else if (Array.isArray(text)) {
    text.forEach((textItem) => formBody.push(`text=${encodeURIComponent(textItem)}`));
  }

  formBody = formBody.join('&');

  return deeplClient.post('/translate', formBody, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
};

class DeeplError extends Error {
  constructor(message, details = {}) {
    super(message);
    this.details = details;
  }
}

const DEEPL_ERROR_TYPE = {
  MAX_TRANSLATIONS_LIMIT: 'MAX_TRANSLATIONS_LIMIT',
};

export {
  getTranslationsRequest,
  DEEPL_AVAILABLE_SOURCE_LANGUAGES,
  DEEPL_AVAILABLE_TARGET_LANGUAGES,
  MAX_TRANSLATIONS_LIMIT_PER_REQUEST,
  DeeplError,
  DEEPL_ERROR_TYPE,
};
