import styled, { css } from 'styled-components';
import { ModalActions as SfxModalActions } from '@scaleflex/ui/core';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';


const StyledModalActions = styled(SfxModalActions)(
  ({ theme: { palette } }) => css`
    background: ${palette[PC.BackgroundPrimary]};
    /* padding-top: 20px;
    padding-bottom: 20px; */
    text-align: right;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid ${palette[PC.BordersPrimary]};
  `,
);

export default StyledModalActions;
