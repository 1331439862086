import styled from 'styled-components';


const FlagImage = styled.img`
  height: 15px;
  width: 25px;
  margin-left: 12px;
  margin-right: 8px;
  cursor: pointer;
`;


const Styled = {
  FlagImage,
};

export default Styled;
