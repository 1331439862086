import styled, { css } from 'styled-components';
import { Color as PC } from '@scaleflex/ui/utils/types/palette/color';
import { MenuItem as SfxMenuItem } from '@scaleflex/ui/core';
import Box from '../../../../../../../box';


const MenuItem = styled((props) => <div><SfxMenuItem {...props} /></div>)(({ theme: { palette } }) => `
  color: ${palette[PC.TextPrimary]};

  .SfxMenuItem-prefix {
    margin-bottom: auto;
  }
`);

const Item = styled.span(
  ({ theme: { palette } }) => css`
    margin-left: 8px;
    color: ${palette[PC.TextPrimary]}
  `,
);

const ImageContainer = styled(Box)(() => css`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-field__container {
    position: relative;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    &:hover {
      .image-field__actions {
        opacity: 1;
      }

      .image-field__image-container > * {
        opacity: 0.6;
      }
    }
  }

  .image-field__image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      border-radius: 50%;
    }
  }

  .image-field__image-container {
    background: linear-gradient(0deg, rgba(93, 109, 126, 0.67), rgba(93, 109, 126, 0.67));
  }

  .image-field__actions {
    z-index: 2;
    opacity: 0;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg.clickable-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* color: transparent; */

    circle {
      pointer-events: auto;
      cursor: pointer;
      fill: transparent;
      /* stroke: black; */
    }
  }
`);

const Styled = {
  MenuItem,
  Item,
  ImageContainer,
};

export default Styled;
